import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import Cases from '../../components/Cases';
import TextPart from '../../components/TextPart';
class WorkPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(179, 172, 172, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/work/work.jpg"
          imageAlt="ourservices">
          <h1>Our work</h1>
          <h3 className="banner-header">
            Our small, large and Fortune 500 clientele bolsters our capabilities each and every day to stay abreast of technological developments. We have
            developed unique solutions to meet our clients’ requirements.
          </h3>
        </TextAndBackground>
        <TextPart>
          <p>We strive to achieve delivery quality in everything that we do and always ensure that customers get the best deliverable.</p>
        </TextPart>
        <Cases />
      </div>
    );
  }
}

export default WorkPage;
