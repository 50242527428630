import React, {PureComponent} from 'react';

class TextPart extends PureComponent {
  constructor(props) {
    super(props);
    this.style = {
      backgroundColor: this.props.bgColor || 'transparent',
      color: this.props.color || '#333',
      paddingBottom: this.props.paddingBottom || '0'
    };
  }
  state = {};
  render() {
    return (
      <div style={this.style} className={this.props.className} data-aos="fade-up">
        <div className="container">
          <div className={this.props.nopadding === true ? '' : 'boxx'}>
            <div className="row">
              <div className={`col-xs col-md col-sm col-lg ${this.props.center === true ? 'text-center ' : ''}`}>
                <div className="box">{this.props.children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TextPart;
