import React, {PureComponent} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
class IdeaSection extends PureComponent {
  state = {};
  render() {
    return (
      <TextAndBackground center={true} bgColor="rgb(246, 250, 255)">
        <div className="contact-us-container">
          <h3>We are here to help. Let's discuss your idea.</h3>
          <a href="#contact_us" className="button" data-aos="fade-up" data-aos-duration="4000">
            Contact Us
          </a>
        </div>
      </TextAndBackground>
    );
  }
}

export default IdeaSection;
