import React, {PureComponent} from 'react';
import StickyBox from 'react-sticky-box';

import PropTypes from 'prop-types';
import ImageZoom from 'react-medium-image-zoom';
import './TwoPartScroller.scss';
class TwoPartScroller extends PureComponent {
  state = {};
  constructor(props) {
    super(props);
    this.style = {
      backgroundColor: this.props.bgColor || 'transparent'
    };
  }

  render() {
    return (
      <div style={this.style} data-aos="fade-up" data-aos-duration="1000">
        <div className="container">
          <div className="side-by-side boxx">
            {this.props.stickRight === true && (
              <StickyBox className="content-sidebar col-xs col-md col-sm col-lg sticky-left-content" id="st">
                <div className="box"> {this.props.children} </div>
              </StickyBox>
            )}

            <div className="content-body col-xs col-md col-sm col-lg">
              <div className="box">
                {this.props.multipleImages ? (
                  this.props.imageSrcs.map((x, i) => {
                    return (
                      <ImageZoom
                        key={i}
                        image={{
                          src: x,
                          alt: `image_${1}`,
                          className: 'img'
                        }}
                        zoomImage={{
                          src: 'bridge-big.jpg',
                          alt: 'Golden Gate Bridge'
                        }}
                      />
                    );
                  })
                ) : (
                  // <img src={this.props.imageSrc} alt={this.props.imageAlt} />
                  <ImageZoom
                    image={{
                      src: this.props.imageSrc,
                      alt: this.props.imageAlt,
                      className: 'img'
                    }}
                    zoomImage={{
                      src: 'bridge-big.jpg',
                      alt: 'Golden Gate Bridge'
                    }}
                  />
                )}
              </div>
            </div>
            {!this.props.stickRight && (
              <StickyBox className="content-sidebar col-xs col-md col-sm col-lg sticky-right-content" id="st">
                <div className="box"> {this.props.children} </div>
              </StickyBox>
            )}
          </div>
        </div>
      </div>
    );
  }
}
TwoPartScroller.propTypes = {
  // imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
};
export default TwoPartScroller;
