import React, { PureComponent } from 'react';

class MultiPart extends PureComponent {
  state = {};
  constructor(props) {
    super(props);
    this.partStyle = {
      backgroundColor: this.props.bgColor || 'transparent',
      color: this.props.color || 'black',
      backgroundImage: `url(${this.props.imageSrc})`
    };
  }
  render() {
    return (
      <div style={this.partStyle}>
        <div className="container">
          <div className="boxx">
            <div className="row no-margin">
              {this.props.children && Array.isArray(this.props.children) ? (
                this.props.children.map((x, i) => {
                  return (
                    <div key={i} className="col-xs col-md col-sm col-lg">
                      <div className="box">{x}</div>
                    </div>
                  );
                })
              ) : (
                <div className="col-xs col-md col-sm col-lg">
                  <div className="box">{this.props.children}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MultiPart;
