// import TwoPart from '../../components/TwoPart';
import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import TextAndBackground from '../../components/TextAndBackground';
import Icon from '../../components/Icon';
import Cases from '../../components/Cases';
import IdeaSection from '../common/IdeaSection';
class DocspacePage extends Component {
  state = {};
  render() {
    var imagesArr = [],
      solutionImagesArr = [];
    for (var i = 1; i < 2; i++) {
      solutionImagesArr.push(`/images/docspace/Mockup/sol_${i}.jpg`);
    }
    for (i = 1; i < 2; i++) {
      imagesArr.push(`/images/docspace/Mockup/approach_${i}.jpg`);
    }
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(253, 253, 253, 0.01), rgb(195, 187, 176))'}
          imageSrc="/images/docspace/docspace.jpg"
          imageAlt="docspace">
          <h1>Docspace</h1>
          <h3 className="banner-header">How we built a lightweight web tool to access Documentum.</h3>
        </TextAndBackground>
        <TextPart>
          <div>
            <h2>Challenge</h2>
            <p>
              Documentum Webtop, the current web-based tool to access Documentum repositories is archaic and dependent on Internet Explorer. Most of the
              Enterprises who use Documentum face compatibility issues with Webtop &amp; internet explorer. Webtop replacement offered by EMC is expensive for
              most of the users. We identified that a lot of users do not use all the features of Webtop and do not want to depend on one browser.
            </p>
          </div>
        </TextPart>
        <TwoPartScroller stickRight bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Approach</h2>
            <p>
              It is evident to us that we need to build a fast web-based tool that is compatible with multiple browsers. The critical consideration for us is
              the page loading time, ease of accessing the tool. We modeled the solution along the lines of google docs which is well known and widely used by
              users.
            </p>
          </div>
        </TwoPartScroller>
        <TwoPartScroller imageSrcs={solutionImagesArr} bgColor="#f6faff" multipleImages={true} imageAlt="images">
          <h2>Solution</h2>
          <p>
            Documentum users will log in using their credentials or via Active Directory. A simple page with most widely used features is presented to the user
            with abilities to change the attributes, permissions quickly. Users can use shortcuts to access DocSpace features.
          </p>
        </TwoPartScroller>

        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>We developed Frontend using HTML5, CSS3, and AngularJS and backend using Java.</p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" imageText="AngularJS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/java.png" imageAlt="java" imageText="Java" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>
              We sold DocSpace to multiple mid &amp; enterprise level customers accounting for $30K revenue in product sales. DocSpace is discontinued in 2018
              to focus on other products.
            </p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default DocspacePage;
