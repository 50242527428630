import React, { Component } from 'react';

class Page404 extends Component {
  render() {
    return (
      <div className="container">
        <div className={'boxx'}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h1>
            <span aria-labelledby="stop" role="img">
              🗺️
            </span>
            &emsp; Lost your way around?
          </h1>
          <br />&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
          <a href="/" className="button">
            lets go home
          </a>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Page404;
