import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import TextPart from '../../components/TextPart';
import Icon from '../../components/Icon';
import './technology.scss';
import WhyCooseUs from '../../components/WhyChoseUs';
class FullStackDevelopmentPage extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(rgba(216, 214, 214, 0.99), rgba(60, 60, 60, 0.74))'}
          color="white"
          imageSrc="/images/services/full-stack-development.jpg"
          imageAlt="fullstack">
          <h1>Full Stack Development</h1>
          <h3>Building the end-to-end software experience</h3>
        </TextAndBackground>
        <TextPart>
          <p>
            Our full-stack developers bring in extensive knowledge about everything that is involved with developing an application – frontend to the backend to
            deployment. Our developers understand all the details of the application and can switch between backend and frontend when needed thus increasing the
            delivery quality and reducing the time it takes to build the application.
          </p>
          <p>We use the latest technologies that are suitable to build the application based on your requirements.</p>
          <div>
            <h2>Our technology focus areas are</h2>
            <h3>Frontend Technologies</h3>
            <div>
              <br />
              <div className="row technologies">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                    <div className="desc">Creating Web experiences for any device.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                    <div className="desc">Design stunning looking website.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" imageText="AngularJS" />
                    <div className="desc">Google's ecosystem for flexibility in designing and creating dynamic web experiences.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/react.png" imageAlt="react" imageText="React" />
                    <div className="desc">Facebook's performant Web UI library to build user interfaces.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/aspnet.png" imageAlt="asp" imageText="ASP.NET MVC" />
                    <div className="desc">Microsoft's Solution for creating enterprise grade Web applications.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/bootstrap.svg" imageAlt="bootstrap" imageText="Bootstrap" />
                    <div className="desc">Quickly build rich mobile first websites.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Backend Technologies</h2>
            <div>
              <br />
              <div className="row technologies">
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/ts.png" imageAlt="typescript" imageText="TypeScript" />
                    <div className="desc">A typesafe environment for Javascript Application.</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/firebase.png" imageAlt="firebase" imageText="Firebase" />
                    <div className="desc">Build quick, offline ready, realtime apps on the cloud.</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/node.png" imageAlt="node" imageText="NodeJS" />
                    <div className="desc">Cross-platform JavaScript environment for running web applications.</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/spark.jpg" imageAlt="spark" imageText="Spark" />
                    <div className="desc">Open-source cluster-computing framework for big data processing.</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/scala.png" imageAlt="scala" imageText="Scala" />
                    <div className="desc">A modern, functional language, general-purpose programming language built on JVM</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/mongodb.png" imageAlt="mongodb" imageText="MongoDB" />
                    <div className="desc">Free and Open-source, cross-platform, document-oriented NoSQL database built to scale.</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/elasticsearch.png" imageAlt="elasticsearch" imageText="Elasticsearch" />
                    <div className="desc">A cross-platform search engine build for speed and scale.</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/neo4j.png" imageAlt="neo4j" imageText="Neo4j" />
                    <div className="desc">Open-source, cross-platform graph database</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/redis.png" imageAlt="redis" imageText="Redis" />
                    <div className="desc">A super fast cache with data structures support.</div>
                  </div>
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/sql.png" imageAlt="spl" imageText="SQL Server" />
                    <div className="desc">Enterprise grade RDBMS</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Languages</h2>
            <div>
              <br />
              <div className="row technologies">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/ts.png" imageAlt="typescript" imageText="TypeScript" />
                    <div className="desc">A typesafe environment for Javascript Application.</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/scala.png" imageAlt="scala" imageText="Scala" />
                    <div className="desc">A modern, functional language, general-purpose programming language built on JVM</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/java.png" imageAlt="Java" imageText="Java" />
                    <div className="desc">Free, open-source, cross platform general purpose programming language </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/aspnet.png" imageAlt="dotnet" imageText="Dotnet" />
                    <div className="desc">Microsoft's software framework for creating general purpose application</div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div className="tech-container">
                    <Icon imageSrc="/images/icons/js.png" imageAlt="Javascript" imageText="Javascript" />
                    <div className="desc">Modern, functional programming language for the web.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <WhyCooseUs />
      </div>
    );
  }
}

export default FullStackDevelopmentPage;
