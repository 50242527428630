import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import TextPart from '../../components/TextPart';
import WhyCooseUs from '../../components/WhyChoseUs';
class DataVirtualization extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          backgroudPosition={'50% center'}
          gradient={'linear-gradient(45deg, rgba(191, 185, 185, 0.01), rgba(80, 80, 80, 0.74))'}
          color="white"
          imageSrc="/images/services/data-virtualization-1.jpg"
          imageAlt="application">
          <h1>Data Virtualization</h1>
          <h3>A Logical Data Layer For Any Data, Anywhere</h3>
        </TextAndBackground>
        <TextPart>
          <p>
            To be leaders, businesses need to take advantage of their data. Gaining an advantage is a technical challenge than ever as business requirements are
            ever changing, analytic workloads are exploding, and data is now widely-distributed across on-premises, big data, the Internet of Things, and the
            Cloud.
          </p>
          <p>
            Data Virtualization software enables you to integrate data at big data scale effectively. DV enables you to build and manage virtualized datasets
            and data services that access, transform, and deliver the data your business requires to accelerate revenue, reduce costs, lessen risk, improve
            compliance, and more.
          </p>
          <p>
            ContentErra has a dedicated team with vast experience in delivering DV solutions to variety of industry verticals with hands on experience in DV
            tools such as TIBCO, Denodo, etc.
          </p>
        </TextPart>
        <WhyCooseUs />
      </div>
    );
  }
}

export default DataVirtualization;
