import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import TextPart from '../../components/TextPart';
import WhyCooseUs from '../../components/WhyChoseUs';
class ApplicationMaintenancePage extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(191, 185, 185, 0.01), rgba(80, 80, 80, 0.74))'}
          color="white"
          imageSrc="/images/services/application-maintainence.jpg"
          imageAlt="application">
          <h1>Application Maintenance</h1>
          <h3>Fine tune the application performance with a flexible structure to align with your business objectives</h3>
        </TextAndBackground>
        <TextPart>
          <p>
            We offer a wide range of support services to meet your business needs. Research shows that companies spend more time on software maintenance than
            software development. Our software maintenance &amp; support services will enable you to focus on the core business by outsourcing the
            responsibilities related to existing applications to us thereby cutting the costs and increasing profitability.
          </p>
          <h3>We provide the following types of maintenance and support</h3>
          <div className="subheading">
            <p>
              <strong>Perfective – </strong>
              Perfective maintenance &amp; support involves improving application performance, maintainability, and other attributes. As part of the
              enhancements, we restructure the code by modifying, deleting, or inserting pieces of code in the application.
            </p>
            <p>
              <strong>Corrective – </strong>
              Corrective maintenance &amp; support involves fixing the problems in the application which can be in logic, design, or any fundamental issues with
              the code.
            </p>
            <p>
              <strong>Preventive – </strong>
              Preventive maintenance &amp; support involves analyzing the past occurrences of problems, and customer feedback to improve software
              maintainability &amp; stability to prevent problems in the future.
            </p>
          </div>
          <h3>Why choose us for Software Maintenance &amp; Support?</h3>
          <div className="subheading">
            <p>
              <strong>Quick Turnover – </strong>
              We respond to your requests promptly
            </p>
            <p>
              <strong>Technical Competence – </strong>
              Our strong technical competence and staying abreast with the latest technologies will ensure we maintain your applications according to the latest
              standards.
            </p>
            <p>
              <strong>Flexible Pricing – </strong>
              We offer Flexible support packages, multiple Pricing, and delivery options that suit your needs.Flexible Pricing models to suit on your needs –
              Fixed, variable, or a custom package
            </p>
          </div>
        </TextPart>
        <WhyCooseUs />
      </div>
    );
  }
}

export default ApplicationMaintenancePage;
