import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class TwoPartImageText extends PureComponent {
  state = {};
  render() {
    const children = this.props.children;
    return (
      <div className="container boxx">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="box">
              <img src={this.props.imageSrc} alt={this.props.imageAlt} width="100%" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="box">
              <h3>{children}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
TwoPartImageText.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};
export default TwoPartImageText;
