import React, { Component } from 'react';
import TextAndBackground from '../../components/TextAndBackground';
class MVPBuildingPage extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(195, 192, 192, 0.01), rgba(216, 216, 216, 0.74))'}
          imageSrc="/images/services/mvp.jpg"
          imageAlt="mvp">
          <h1>MVP Building</h1>
          <h3>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s, when
            an unknown printer
          </h3>
        </TextAndBackground>
      </div>
    );
  }
}

export default MVPBuildingPage;
