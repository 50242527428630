// import TwoPart from '../../components/TwoPart';
import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import TextAndBackground from '../../components/TextAndBackground';
import Icon from '../../components/Icon';
import Cases from '../../components/Cases';
import IdeaSection from '../common/IdeaSection';
class TripGain extends Component {
  state = {};
  render() {
    var imagesArr = [],
      solutionImagesArr = [];
    for (var i = 1; i < 3; i++) {
      solutionImagesArr.push(`/images/tripgain/Mockup/sol_${i}.jpg`);
    }
    for (i = 1; i < 3; i++) {
      imagesArr.push(`/images/tripgain/Mockup/approach_${i}.jpg`);
    }
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(255, 255, 255, 0.01), rgb(99, 72, 68))'}
          color="white"
          imageSrc="/images/tripgain/tripgain.jpg"
          imageAlt="tripgain">
          <h1>UI for B2B Travel Booking System</h1>
          <h3 className="banner-header">How we created a Progressive Web Application for an existing end to end travel management system.</h3>
        </TextAndBackground>
        <TextPart>
          <div>
            <h2>Challenge</h2>
            <p>
              Client had an existing travel management system built on outdated frameworks and the UI looks outdated. The client was facing difficulties in
              maintaining and integrating the newer features that are being built in the backend. The old style UI is not helping them to sell the application
              at a scale that they want. The client approached us to rebuild the UI for the application for web and mobile platforms.
            </p>
          </div>
        </TextPart>
        <TwoPartScroller stickRight bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Approach</h2>
            <p>
              Client’s primary target customers were small to medium companies. The client told us that their users would use the web more than the mobile
              application and a slight slowness is acceptable to the users on mobile. We have decided to use React framework to build the application and make
              it a progressive web app.
            </p>
          </div>
        </TwoPartScroller>
        <TwoPartScroller imageSrcs={solutionImagesArr} bgColor="#f6faff" multipleImages={true} imageAlt="images">
          <h2>Solution</h2>
          <p>
            The users will login to the system and be able to search for flights, hotels, and cabs. Approval workflow is provided to the users to select the
            options and pass it to their supervisor for the approval. An inbuilt expense management system also helps the users to track and get approvals on
            their business trip expenses. We also incorporated the client’s proprietary algorithm that suggests the best combo for flights, hotels, and cabs for
            the users given the travel destination
          </p>
        </TwoPartScroller>

        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>We used HTML5, CSS3, ReactJS to build the front end.</p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/react.png" imageAlt="react" imageText="React" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>
              Client rolled out the new application in July 2018, and the initial feedback from existing clients was very positive. We are expected to know the
              sales statistics from the client in October 2018.
            </p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default TripGain;
