import React, {Component} from 'react';
import TextPart from '../../components/TextPart';

import TextAndBackground from '../../components/TextAndBackground';
import Cases from '../../components/Cases';
import WhyCooseUs from '../../components/WhyChoseUs';
import AllServices from '../services/AllServices';

class HomePage extends Component {
  state = {};
  render() {
    return (
      <div>
        <TextAndBackground blendWithNavBar={true} gradient={'linear-gradient(#e1f0fbf5, rgba(3, 169, 244, 0.5))'} imageSrc="/images/home.jpg" imageAlt="home">
          <br />
          <br />
          <br />

          <TextPart nopadding center={true}>
            <h1>We Transform your Business Goals and Visions into Products or Solutions</h1>
            {/* <h3>IT Consulting Services provider specialized in Web and Mobile Application Development</h3> */}
            <br />
            <br />
            <br />
            <br />
            <a href="/work" className="button" data-aos="fade-up" data-aos-duration="4000">
              Check our work
            </a>
            &emsp;
            <a href="#contact_us" className="button" data-aos="fade-up" data-aos-duration="4000">
              Get in touch
            </a>
          </TextPart>
        </TextAndBackground>
        <AllServices />
        {/* <TextPart center bgColor="rgb(246, 250, 255)">
          <h3>MVP</h3>
          <div className="boxx" data-aos="fade-up">
            <h3 style={{ margin: 0 }}>
              We help you convert your idea to MVP.
              <a href="/services/mvp-building" className="button" style={{ marginLeft: '24px' }}>
                Learn more
              </a>
            </h3>
          </div>
        </TextPart> */}
        <Cases splitCount={3} itemsToShow={2} showMore />
        <WhyCooseUs />
      </div>
    );
  }
}

export default HomePage;
