import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import TextPart from '../../components/TextPart';
import Icon from '../../components/Icon';
import WhyCooseUs from '../../components/WhyChoseUs';
class MobileDevelopmentPage extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(191, 185, 185, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/services/mobile-development.jpg"
          imageAlt="mobile">
          <h1>Mobile Development</h1>
          <h3>Mobility and Flexibility in app building, integration, and testing while ensuring security</h3>
        </TextAndBackground>
        <TextPart>
          <p>
            Our adroit mobile development team will help you to build a high-quality mobile app that meets your requirements. We are experts in building mobile
            applications in native Android, iOS, hybrid frameworks like React Native, ionic, Xamarin, and Progressive Web Applications. Depending on your
            requirements and future scope of the application, we select a framework to build the app.
          </p>
        </TextPart>
        <TextPart>
          <h2>Native Application Development</h2>
          <p>
            Native applications are written in platform-specific languages like Android and iOS. Native apps have the best performance and are the best bet if
            the application involves complex animations or require the latest features that a mobile platform has to offer. Because the application has to be
            developed on multiple platforms, this is an expensive option.
          </p>
          <div className="row technologies">
            <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/android.png" imageAlt="android" imageText="Android" />
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/ios.jpg" imageAlt="ios" imageText="IOS" />
            </div>
          </div>
        </TextPart>
        <TextPart>
          <h2>Hybrid Application</h2>
          <p>
            Hybrid or cross-platform application frameworks allows building applications for different platforms with the same code. The performance is lower
            than native but the time to build the app on multiple platforms is also lower. Hybrid frameworks and the community around it are getting better by
            day. Hybrid applications are the best bet if you want to build an MVP to test the market or if you want an app with not so complex and latest
            features.
          </p>
          <div className="row technologies">
            <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/react.png" imageAlt="reactnative" imageText="React Native" />
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/cardova.jpg" imageAlt="cardova" imageText="Cordova" />
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/ionic.jpg" imageAlt="ionic" imageText="Ionic" />
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/xamarin.png" imageAlt="xamarin" imageText="Xamarin" />
            </div>
          </div>
        </TextPart>
        <TextPart>
          <h2>Progressive Web App</h2>
          <p>
            Progressive Web App uses modern web capabilities to deliver an app-like user experience. PWA is a web application that supports offline capabilities
            and some basic mobile features like notifications on the mobile platform. There is no need to install the app. PWA reduces the costs considerably
            and is an excellent choice if you are looking to build just a web app that can double up as a mobile application too.
          </p>
        </TextPart>
        <WhyCooseUs />
      </div>
    );
  }
}

export default MobileDevelopmentPage;
