import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class FluidImage extends PureComponent {
  state = {};
  render() {
    return (
      <div className="FluidImage">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs col-md col-sm col-lg">
              <div className="box">
                <img
                  src={this.props.imageSrc}
                  alt={this.props.imageAlt}
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FluidImage.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
};
export default FluidImage;
