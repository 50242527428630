import React, {Component} from 'react';
import Container from '../../components/Container';
import './contact.css';
import firebase from '../../components/Firebase/Firebase';
class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDetails: {
        name: '',
        email: '',
        subject: ''
      },
      showMessage: false
    };
  }
  handleChange(event) {
    var eve = {...event};
    this.setState(prevState => ({
      contactDetails: {
        ...prevState.contactDetails,
        [eve.target.name]: eve.target.value
      }
    }));
  }
  saveDetails(e) {
    this.setState({
      showMessage: false
    });
    e.preventDefault();
    var key = firebase
      .database()
      .ref('/contact-details')
      .push().key;
    var data = {
      key: key,
      name: this.state.contactDetails.name,
      email: this.state.contactDetails.email,
      subject: this.state.contactDetails.subject,
      time: new Date().getTime()
    };
    firebase
      .database()
      .ref(`/contact-details/${key}`)
      .set(data);
    this.setState({
      showMessage: true,
      contactDetails: {
        name: '',
        email: '',
        subject: ''
      }
    });
  }
  render() {
    this.styles = {
      form: {
        boxSizing: 'border-box'
      }
    };
    return (
      <div className="container">
        <div className={this.props.nopadding === true ? '' : 'boxx'}>
          {this.props.hideHeadings === false && (
            <div>
              <h2>Hello there !</h2>
              <p> Drop in you contact info and we&#39;ll get in touch </p>
            </div>
          )}
          <Container>
            <div className="col-xs col-md col-sm col-lg contact-container">
              <form onSubmit={e => this.saveDetails(e)}>
                <fieldset style={this.styles.form}>
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    autoComplete="name"
                    required
                    placeholder="Your Name"
                    id="name"
                    name="name"
                    onChange={e => this.handleChange(e)}
                    value={this.state.contactDetails.name}
                  />
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Contact Email"
                    id="email"
                    name="email"
                    onChange={e => this.handleChange(e)}
                    value={this.state.contactDetails.email}
                  />
                  <label htmlFor="subject">Subject</label>
                  <textarea
                    rows="5"
                    placeholder="Tell us a little bit about yourself and your project."
                    required
                    id="subject"
                    name="subject"
                    onChange={e => this.handleChange(e)}
                    value={this.state.contactDetails.subject}
                  />

                  <input className="button-primary" type="submit" value="Send" />
                  {this.state.showMessage && (
                    <p className="success-message">
                      Thank you for contacting us. We will get back to you shortly.
                      <span className="close" onClick={() => this.setState({showMessage: false})}>
                        &times;
                      </span>
                    </p>
                  )}
                </fieldset>
              </form>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default ContactPage;
