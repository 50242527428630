import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
class Icon extends PureComponent {
  state = {};
  constructor(props) {
    super(props);
    this.partStyle = {
      backgroundColor: this.props.bgColor || 'white',
      height: this.props.height || ''
    };
  }
  render() {
    return (
      <div style={this.partStyle} className="icon-container">
        <img className="icon-image" style={{height: this.partStyle.height || ''}} src={this.props.imageSrc} alt={this.props.imageAlt} />
        {this.props.imageText && <p className="icon-title">{this.props.imageText}</p>}
      </div>
    );
  }
}
Icon.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
  // imageText: PropTypes.string.isRequired
};
export default Icon;
