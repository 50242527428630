// import TwoPart from '../../components/TwoPart';
import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import TextAndBackground from '../../components/TextAndBackground';
import Icon from '../../components/Icon';
import Cases from '../../components/Cases';
import IdeaSection from '../common/IdeaSection';
class PharmaLite extends Component {
  state = {};
  render() {
    var imagesArr = [],
      solutionImagesArr = [];
    for (var i = 1; i < 3; i++) {
      solutionImagesArr.push(`/images/pharmalite/Mockup/sol_${i}.jpg`);
    }
    for (i = 1; i < 2; i++) {
      imagesArr.push(`/images/pharmalite/Mockup/approach_${i}.jpg`);
    }
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(255, 255, 255, 0.01), rgb(99, 72, 68))'}
          color="white"
          imageSrc="/images/pharmalite/pharmalite.jpg"
          imageAlt="pharmalite">
          <h1>UI for Pharmaceutical Manufacturing Product</h1>
          <h3 className="banner-header">
            How we worked with a startup to build the UI for their manufacturing process management product for pharmaceutical industry.
          </h3>
        </TextAndBackground>
        <TextPart>
          <div>
            <h2>Challenge</h2>
            <p>
              The client is a startup with strong experience in building the backend for an application but has limited skills for building the front end. The
              client was looking for a high quality product with a quick turnaround. The client approached us to be their development partner for building the
              UI in parallel to the backend.
            </p>
          </div>
        </TextPart>
        <TwoPartScroller stickRight bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Approach</h2>
            <p>
              The client wanted us to build the application on specific technologies of their choice. The client also had wireframes for majority of the
              screens. Our approach for this engagement was to build the application according to their UI design and test the application.
            </p>
          </div>
        </TwoPartScroller>
        <TwoPartScroller imageSrcs={solutionImagesArr} bgColor="#f6faff" multipleImages={true} imageAlt="images">
          <h2>Solution</h2>
          <p>
            The application is a sophisticated end to end manufacturing process management tool. There are multiple roles, departments, and workflows within the
            system which are configured by the System administrator. Users have a dashboard with a list of activities assigned to them including approvals, and
            specific actions that they can perform depending on their roles. The application also has a comprehensive reporting and email notification
            abilities.
          </p>
        </TwoPartScroller>

        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>We used HTML5, CSS3, AngularJS, ASP.NET to build the front end.</p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" imageText="AngularJS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/aspnet.png" imageAlt="asp" imageText="ASP.NET MVC" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>Client deployed the solution in one Pharmaceutical company in July 2018. </p>
            <p>
              <strong>We are starting phase-2 of the project involving more components and detailed reports in August 2018.</strong>
            </p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default PharmaLite;
