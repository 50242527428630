import React, { PureComponent } from 'react';

class Container extends PureComponent {
  state = {};
  constructor(props) {
    super(props);
    this.partStyle = {
      backgroundColor: this.props.bgColor || 'transparent',
      color: this.props.color || 'black',
      backgroundImage: `url(${this.props.imageSrc})`
    };
  }
  render() {
    return (
      <div style={this.partStyle} data-aos="fade-up" data-aos-duration="1000">
        <div className={this.props.isFluid ? 'container-fluid' : 'container'}>
          <div className="boxx">
            <div className="row no-margin">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Container;
