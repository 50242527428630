// import TwoPart from '../../components/TwoPart';
import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import TextAndBackground from '../../components/TextAndBackground';
import Icon from '../../components/Icon';
import Cases from '../../components/Cases';
import IdeaSection from '../common/IdeaSection';
class FedBizintelPage extends Component {
  state = {};
  render() {
    // var settings = {
    //   autoPlay: true,
    //   infiniteLoop: true,
    //   interval: 2000,
    //   emulateTouch: true,
    //   slidesToScroll: 1
    // };
    var imagesArr = [],
      solutionImagesArr = [];
    for (var i = 6; i < 8; i++) {
      solutionImagesArr.push(`/images/fedbizintel/Mockup/${i}.jpg`);
    }
    for (i = 1; i < 3; i++) {
      imagesArr.push(`/images/fedbizintel/Mockup/${i}.jpg`);
    }
    // imagesArr = ['/images/fedbizintel/1.jpg', '/images/fedbizintel/2.jpg', '/images/fedbizintel/3.jpg', '/images/fedbizintel/4.jpg'];
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(255, 255, 255, 0.01), rgb(99, 72, 68))'}
          color="white"
          imageSrc="/images/fedbizintel/fedbizintel.jpg"
          imageAlt="fedbizintel">
          <h1>FEDBIZINTEL</h1>
          <h3 className="banner-header">How Government Contractors saved $60k an year with FedBizIntel.</h3>
        </TextAndBackground>
        <TextPart>
          <div>
            <h2>Challenge</h2>
            <p>
              US Government Contractors, on an average, spend <strong>$70k - $100k</strong> each year on collating, researching, and analyzing government
              contracts, and it&#39;s not an easy task to undertake. The information required to compete is scattered across multiple sources and requires a lot
              of analysis &amp; human intervention. FedBizIntel’s team with their extensive experience in government contracting space identified a need for a
              comprehensive market intelligence and an automatic lead generator tool for government contractors. FedBizIntel approached ContenTerra to become
              their development partner and build the platform.
            </p>
          </div>
        </TextPart>
        <TwoPartScroller stickRight bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Approach</h2>
            <p>
              When we discussed the requirements and overall business model with FedBizIntel, we decided to build a web application hosted on the cloud that is
              secure &amp; scalable to meet their user growth, and mitigate the cost per user.{' '}
            </p>
          </div>
        </TwoPartScroller>
        <TwoPartScroller bgColor="#f6faff" imageSrcs={solutionImagesArr} multipleImages={true} imageAlt="images">
          <h2>Solution</h2>
          <p>
            FedBizIntel gathers data from multiple data sources and analyzes the data to forecast, recommend opportunities to contractors based on their
            profile. FedBizIntel provides a web application for users to register, create a profile, and access the intelligence to get notifications, reminders
            on opportunities
          </p>
        </TwoPartScroller>

        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>
                The web front end of the application is written using ASP.NET MVC, AngularJS, HTML5, CSS3, bootstrap. Opportunity and contract information is
                stored in Elasticsearch, a NoSQL data store with quick searching capabilities. The user preferences and saved opportunities are stored in SQL
                Server. The information about recent searches, home-feed is stored in Redis, a caching tool.
              </p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/aspnet.png" imageAlt="asp" imageText="ASP.NET MVC" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" imageText="AngularJS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/bootstrap.svg" imageAlt="bootstrap" imageText="Bootstrap" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/elasticsearch.png" imageAlt="elasticsearch" imageText="Elasticsearch" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/redis.png" imageAlt="redis" imageText="Redis" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/sql.png" imageAlt="spl" imageText="SQL" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>
              FedBizIntel’s customers, on an average, saved 60% of the time spent on searching &amp; analyzing opportunities, which translates to $45k - $60k
              savings. Users also replaced the existing pipeline management &amp; collaboration tool with FedBizIntel’s inbuilt capabilities translating to
              another $5k savings per year. Users also have access to data at their disposal which otherwise is difficult to obtain.
            </p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default FedBizintelPage;
