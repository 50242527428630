import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import TextPart from '../../components/TextPart';
import WhyCooseUs from '../../components/WhyChoseUs';
class OffshoreDevelopmentPage extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(191, 185, 185, 0.8), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/services/offshore.jpg"
          imageAlt="offshore">
          <h1>Offshore Development</h1>
          <h3>Ensures information security while providing remote resources for better productivity and cost savings</h3>
        </TextAndBackground>
        <TextPart>
          <p>
            Our offshore development services help a wide range of businesses of different sizes by assisting them with Software development needs and allow
            them to benefit from significant cost savings and business process optimization. Our proven technological expertise, skills, and knowledge will help
            us to provide dependable offshore development services for you. We can be an extension to your current team and deliver custom software that meets
            your needs and drives ROI.
          </p>
          <h3>What we do in offshore development services</h3>
          <ul className="subheading">
            <li>Custom Application Development</li>
            <li>Web Application Development</li>
            <li>Mobile Application Development</li>
            <li>IV &amp; V services</li>
          </ul>
          <div className="offshore-container">
            <h3>Why choose our Offshore Development Services?</h3>
            <p>
              <strong>Cost Savings: </strong>
              Software development costs are around 40-50 percent lower than the US and Europe. Cost savings are mainly due to the cost of labor in India.
            </p>
            <p>
              <strong>Faster time to market:</strong> Because of the difference in time zones, there is almost 24 hours of work done every day reducing the time
              to market which is critical in today’s fast &amp; evolving market.
            </p>
            <p>
              <strong>Flexible Engagement Models:</strong> You can choose between a dedicated team who work just for you along with your existing team or use
              resources on a time &amp; material basis or ask us to deliver something on a fixed price model.
            </p>
            <p>
              <strong>Tailored made approach:</strong> We understand that each client is different and that is why we approach each project differently based on
              your standards and norms. We gel with your existing team &amp; culture to ensure smooth engagement.
            </p>
            <p>
              <strong>Maintain your core focus:</strong> You can concentrate on your core value competencies by leveraging our team for your development needs.
            </p>
          </div>
        </TextPart>
        <WhyCooseUs />
      </div>
    );
  }
}

export default OffshoreDevelopmentPage;
