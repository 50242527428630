import React, {PureComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router';

import './NavBar.scss';
import AOS from 'aos';
class NavBar extends PureComponent {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showMenu: false
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, state) {
    setTimeout(function() {
      AOS.init({
        once: true
      });
    }, 1000);
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
  };
  setMenuRef = node => {
    this.menuRef = node;
  };

  setMenuIconRef = node => {
    this.menuIconRef = node;
  };
  handleClickOutside = event => {
    if (!this.menuRef.contains(event.target) && !this.menuIconRef.contains(event.target)) {
      this.setState({
        showMenu: false
      });
    }
  };
  render() {
    return (
      <div className={`${this.props.type} header-bar`}>
        <nav className="navigation">
          <section className="container">
            <div className="row middle-lg middle-md middle-sm middle-xs">
              <div className="col-lg col-md col-sm col-xs-12">
                <NavLink className="navigation-title" to="/">
                  <img src={this.props.type === 'light' ? '/images/logo_white.png' : '/images/logo.png'} alt="logo" />
                </NavLink>
                <div className="nav-toggle-icon float-right menu-conent" onClick={() => this.toggleMenu()} ref={this.setMenuIconRef}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="menu-icon">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
                  </svg>
                </div>
              </div>
              <div className="col-lg col-md col-sm col-xs-12">
                <div className="row end-lg end-md end-sm no-margin">
                  <div className="col-lg col-md col-sm col-xs-12">
                    <div className="box">
                      <div className={`menu-conent navigation-links ${this.state.showMenu ? 'show-menu' : 'hide-menu'}`} ref={this.setMenuRef}>
                        <NavLink className="navigation-link" activeClassName="activePage" to="/services" onClick={() => this.toggleMenu()}>
                          Services
                        </NavLink>
                        <NavLink className="navigation-link" activeClassName="activePage" to="/work" onClick={() => this.toggleMenu()}>
                          Work
                        </NavLink>
                        <NavLink className="navigation-link" activeClassName="activePage" to="/about" onClick={() => this.toggleMenu()}>
                          About
                        </NavLink>
                        <NavLink className="navigation-link" activeClassName="activePage" to="/career" onClick={() => this.toggleMenu()}>
                          Careers
                        </NavLink>
                        {/* <NavLink className="navigation-link" activeClassName="activePage" to="/contact" onClick={() => this.toggleMenu()}>
                          Contact
                        </NavLink> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </nav>
      </div>
    );
  }
}

export default withRouter(NavBar);
