import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TextAndBackground from '../../components/TextAndBackground';
import Cases from '../../components/Cases';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import Icon from '../../components/Icon';
import IdeaSection from '../common/IdeaSection';

class StuworxPage extends Component {
  state = {};
  render() {
    // };
    var imagesArr = [],
      solutionImagesArr = [];
    for (var i = 1; i < 3; i++) {
      solutionImagesArr.push(`/images/stuworx/Mockup/sol_${i}.png`);
    }
    for (i = 1; i < 2; i++) {
      imagesArr.push(`/images/stuworx/Mockup/approach_${i}.png`);
    }
    return (
      <div className="case-studies">
        <TextAndBackground
          color="white"
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.74))'}
          imageSrc="/images/stuworx/stuworx.jpg"
          backgroudPosition="50% 34%"
          imageAlt="'stuworx'">
          <h1>Stuworx</h1>
          <h3 className="banner-header">How we helped STUWORX launch their Ed-Tech Platform to improve employability among higher education students</h3>
        </TextAndBackground>

        <TextPart>
          <h2>Challenge</h2>
          <p>
            According to market research, only 20% of higher education students in India are employable. STUWORX did market research and identified the
            following causes: large gap between academia &amp; the industry, low attention span among students while learning, and lack of guidance to students.
            STUWORX wanted to build a social learning platform with gamification for students, instructors, and recruiters to collaborate &amp; solve the
            problems. STUWORX chose ContenTerra as their development partner.
          </p>
        </TextPart>
        <TwoPartScroller stickRight bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Approach</h2>
            <p>
              The key considerations for us when developing the product was ease of use, scalability to support more than 1M users, ability to work at low
              internet bandwidth, and support multiple devices. We were involved in a lot of whiteboarding sessions with STUWORX team to understand the
              requirements and define information hierarchy on each page. We also wanted to use open source technologies for minimizing hosting charges &amp;
              large developer base in the ecosystem.
            </p>
          </div>
        </TwoPartScroller>
        <TwoPartScroller bgColor="#f6faff" imageSrcs={solutionImagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Solution</h2>
            <p>
              We modeled the platform along the lines of other famous websites like Facebook, Udacity, &amp; Naukri. Student’s login to the portal, set their
              interests and create posts to share the knowledge which is then rated by other students &amp; instructors on the portal. Students take up courses
              via an inbuilt eLearning module and get certified via an inbuilt assessment module. Progress is measured and gamified. Student’s profiles are
              shared with recruiters. Learning, Assessments, and Recruitment on a single platform makes STUWORX a one-stop portal for students.
            </p>
          </div>
        </TwoPartScroller>
        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>
                We used HTML5, CSS3, AngularJS, TypeScript, Material Design template in front end. The backend was written using TypeScript, NodeJS, Apache
                Spark, Kafka, and Scala. Data is stored in MongoDB, ElasticSearch, Neo4j, and Redis. We used Google analytics &amp; created custom analytics. We
                used Kibana &amp; Logstash for logging.
              </p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" imageText="AngularJS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/ts.png" imageAlt="typescript" imageText="TypeScript" />
                </div>
                {/* <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/md.png" imageAlt="materialdesign" imageText="Material Design" />
                </div> */}
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/node.png" imageAlt="node" imageText="NodeJS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/spark.jpg" imageAlt="spark" imageText="Spark" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/scala.png" imageAlt="scala" imageText="Scala" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/mongodb.png" imageAlt="mongodb" imageText="MongoDB" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/elasticsearch.png" imageAlt="elasticsearch" imageText="Elasticsearch" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/neo4j.png" imageAlt="neo4j" imageText="Neo4j" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/redis.png" imageAlt="redis" imageText="Redis" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/aws.png" imageAlt="aws" imageText="AWS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/kafka.jpg" imageAlt="kafka" imageText="Kafka" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>STUWORX platform is in beta and being used by around 500 users. STUWORX expects the traffic to grow considerably by December 2018.</p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default StuworxPage;
