import React, {Component} from 'react';
import TextAndBackground from '../TextAndBackground';
import OpenPositions from '../../pages/common/OpenPositions';
import Container from '../Container';
import './OpenPositions.scss';
class SoftwareDeveloperIntern extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(179, 172, 172, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/work/work.jpg"
          imageAlt="CareerLogo">
          <h1>Software Developer Intern</h1>
          <h5 className="banner-header">
            Experience : Entry Level or up to 1 year.
            <br />
            Skill Set : HTML5, CSS3, Java, .NET
          </h5>
        </TextAndBackground>
        <Container bgColor={'#efefef'}>
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div className="duties-container">
              <p>
                We are looking for a few software development interns proficient with either HTML, CSS or strong in logical programming (Java, .NET preferred)
                to join our development team. The interns will work with our development team and assist in development activities.
              </p>
              <h4>Duties &amp; Responsibilities</h4>
              <ul>
                <li>Assist Software Developers to build applications or POC’s</li>
                <li>Assist in Research activities</li>
                <li>Handle minor enhancements in existing applications</li>
              </ul>
              <strong>Minimum Qualifications: </strong>
              Degree in Computer Science is preferred. We accept candidates from other branches if they have the required skills.
              <h4 className="required-header">Required Skills</h4>
              <ul>
                <li>Good Understanding of HTML, CSS, JavaScript</li>
                <li>Must be able to think logically and come up with a solution to a problem</li>
                <li>Must have built a small application – can be a web, mobile, or a desktop solution</li>
                <li>Must have the habit of learning individually</li>
                <li>Must have practiced writing lots of code</li>
                <li>Strong understanding of basics – Server – client architecture, application servers and their use, HTTP protocols etc.</li>
                <li>Must know the basics of databases – Schema creation, Normalization, CRUD operations.</li>
              </ul>
              <h4>General Skills:</h4>
              <ul>
                <li>Ability to work in a team environment</li>
                <li>Decent oral and written communication skills</li>
                <li>Participate in meetings and conference calls with clients.</li>
                <li>Initiative to research and learn emerging technologies.</li>
                <li>Completes additional duties as required.</li>
              </ul>
              <h4>About ContenTerra Software Private Limited</h4>
              <p>
                ContenTerra Software Private Limited is a solutions development company focused on web &amp; mobile application development. We are rapidly
                growing and are looking for passionate people to join our team. ContenTerra is an equal opportunity employer.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="duties-container">
              <p>
                <strong>Application Process: </strong>
                Please send your resume to <a href="mailto:careers@contenterra.com">careers@contenterra.com</a>
              </p>
            </div>
          </div>
        </Container>
        <OpenPositions />
      </div>
    );
  }
}

export default SoftwareDeveloperIntern;
