import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class TextAndBackground extends PureComponent {
  gradient = '';
  constructor(props) {
    super(props);
    this.gradient = props.gradient ? `${props.gradient},` : '';
    this.backgroudPosition = props.backgroudPosition ? `${props.backgroudPosition}` : '';
  }
  state = {};
  render() {
    return (
      <div
        className={`${this.props.blendWithNavBar === true ? 'blendWithNavbar' : ''}  ${this.props.center === true ? 'text-center ' : ''} ${
          this.props.class ? this.props.class : ''
        }`}
        style={{
          backgroundColor: this.props.bgColor || 'white',
          color: this.props.color || 'black',
          backgroundImage: `${this.gradient} url(${this.props.imageSrc})`,
          backgroundSize: this.props.backgroundSize ? this.props.backgroundSize : 'cover',
          backgroundPosition: `${this.backgroudPosition}`
        }}>
        <div className="container">
          <div className={this.props.nopadding === true ? '' : 'boxx'}>
            <div className="row">
              <div className="col-xs col-md col-sm col-lg">
                <div className="box" data-aos="fade-up" data-aos-duration="1000">
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
TextAndBackground.propTypes = {
  imageSrc: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  imageAlt: PropTypes.string,
  blendWithNavBar: PropTypes.bool
};
export default TextAndBackground;
