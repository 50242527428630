import React, {PureComponent} from 'react';

class QuotePart extends PureComponent {
  state = {};
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs col-md col-sm col-lg">
            <div className="box">
              <blockquote>
                <p>
                  <em>{this.props.children}</em>
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QuotePart;
