import React, {Component} from 'react';
import TextAndBackground from '../TextAndBackground';
import OpenPositions from '../../pages/common/OpenPositions';
import Container from '../Container';
import Modal from 'react-responsive-modal';
import './OpenPositions.scss';
class ReactDeveloper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenExerciseModal: false
    };
  }
  showOrHideExerciseModal = isOpen => {
    this.setState({
      isOpenExerciseModal: isOpen
    });
  };
  render() {
    return (
      <div>
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(179, 172, 172, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/work/work.jpg"
          imageAlt="CareerLogo">
          <h1>React Developer</h1>
          <h5 className="banner-header">
            Experience : 0 – 3 years.
            <br />
            Skill Set : JavaScript, React, HTML5, CSS3
          </h5>
        </TextAndBackground>
        <Container bgColor={'#efefef'}>
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div className="duties-container">
              <p>
                We are looking for a few frontend developers proficient in developing user interfaces for modern rich internet applications using React to join
                our development team. The candidate will work with our development team on our existing products and projects
              </p>
              <h4>Duties &amp; Responsibilities</h4>
              <ul>
                <li>
                  Develop the user interface conforming to the specified design and integrating with the server-side logic written by the backend developers
                </li>
                <li>Writing reusable, testable, and efficient code</li>
                <li>Design and implementation of low-latency, high-availability, and performant applications</li>
                <li>Implementation of security and data protection</li>
              </ul>
              <h4>Required Skills</h4>
              <ul>
                <li>Exceptional knowledge of HTML5, CSS</li>
                <li>Proficient in JavaScript, including JSON, DOM manipulation and the JavaScript Object Model</li>
                <li>Proficient understanding of cross-browser compatibility issues and workarounds</li>
                <li>Deep and extensive knowledge of JavaScript and the web development ecosystem(Bootstrap, jQuery)</li>
                <li>Thorough understanding of React.js and its core principles</li>
                <li>Ability to use third-party react components to reduce the development time</li>
                <li>Build reusable code and libraries for future use</li>
                <li>Optimize application for maximum speed and scalability</li>
                <li>Ability to create a responsive design and mobile friendly applications </li>
                <li>Experience with common front-end development tools such as Babel, Webpack, NPM, etc.</li>
                <li>Experience in JavaScript build tools like grunt or gulp</li>
              </ul>
              <h4>Good to Have Skills</h4>
              <ul>
                <li>Good Understanding of Typescript</li>
                <li>Capability to deploy applications on multiple platforms (AWS, Azure, etc.)</li>
                <li>Ability to understand business requirements and translate them into technical requirements</li>
              </ul>
              <h4>General Skills:</h4>
              <ul>
                <li>Ability to work in a team environment, including cross-functional teams and teams with business users throughout the company</li>
                <li>Good oral and written communication skills.</li>
                <li>Participate in meetings and conference calls with clients.</li>
                <li>Initiative to research and learn emerging technologies.</li>
                <li>Completes additional duties as required.</li>
              </ul>
              <h4>About ContenTerra Software Private Limited</h4>
              <p>
                ContenTerra Software Private Limited is a solutions development company focused on web &amp; mobile application development. We are rapidly
                growing and are looking for passionate people to join our team. ContenTerra is an equal opportunity employer.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="duties-container">
              <p>
                <strong>Application Process: </strong>
                Please complete the{' '}
                <a href="#" onClick={() => this.showOrHideExerciseModal(true)}>
                  exercise here
                </a>{' '}
                and deploy it in firebase or some hosting provider of your choice. Send us the URL to access it along with your resume to{' '}
                <a href="mailto:careers@contenterra.com">careers@contenterra.com</a> Emails without the URL will not be considered.
              </p>
            </div>
          </div>
        </Container>
        <OpenPositions />
        <Modal open={this.state.isOpenExerciseModal} onClose={() => this.showOrHideExerciseModal(false)} center>
          <h3 className="m-t-0">React Developer</h3>
          <p>
            You are required to display the JSON data from this link{' '}
            <a href="https://www.reddit.com/r/reactjs.json" target="_blank">
              https://www.reddit.com/r/reactjs.json
            </a>{' '}
            in the format below. Feel free to use any colors. You should use the following fields on each card –
            <strong> Title, SelfText_HTML, URL and score </strong>
            (you can find the attributes in the children array). Page should be responsive and use a default resolution of 1280*720.
            <div className="text-center">
              <br />
              <img src="/images/challenge.png" alt="challenge" />
            </div>
          </p>
        </Modal>
      </div>
    );
  }
}

export default ReactDeveloper;
