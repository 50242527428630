import React, {PureComponent} from 'react';
import projectsJson from '../data/projects';
import TextPart from './TextPart';
import Container from './Container';
import {NavLink} from 'react-router-dom';

class Cases extends PureComponent {
  state = {};
  splitCount = 'col-lg-3 col-md-4';
  projects = [];
  constructor(props) {
    super(props);
    this.partStyle = {
      backgroundColor: this.props.bgColor || 'transparent',
      color: this.props.color || '#333',
      backgroundImage: `url(${this.props.imageSrc})`
    };
    switch (props.splitCount) {
      case 1:
        this.splitCount = 'col-lg-12 col-md-12';
        break;
      case 2:
        this.splitCount = 'col-lg-6 col-md-6';
        break;
      case 3:
        this.splitCount = 'col-lg-4 col-md-4';
        break;
      case 4:
        this.splitCount = 'col-lg-4 col-md-4';
        break;
      default:
        this.splitCount = 'col-lg-4 col-md-4';
        break;
    }
    this.projects = projectsJson.projects;
    if (props.itemsToShow) this.projects = this.projects.slice(0, props.itemsToShow);
  }
  render() {
    return (
      <React.Fragment>
        <TextPart center={true} nopadding={true}>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Check out some of our work below
          </h3>
          <Container>
            {this.projects.map((x, i) => {
              return (
                <div key={i} className={`${this.splitCount} col-sm-6 col-xs-12`}>
                  <NavLink
                    style={{background: `url('${x.bg}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
                    className="project-link row center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs"
                    to={x.url}>
                    <div>
                      <p className="project-link-title">{x.name}</p>
                      <span className="project-link-description">{x.description}</span>
                    </div>
                  </NavLink>
                </div>
              );
            })}
            {this.props.showMore && (
              <div className={`${this.splitCount} col-sm-6 col-xs-12`} data-aos="fade-up" data-aos-duration="1000">
                <NavLink className="project-link nocolor row center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs" to={'/work'}>
                  <div>Show More</div>
                </NavLink>
              </div>
            )}
          </Container>
        </TextPart>
      </React.Fragment>
    );
  }
}

export default Cases;
