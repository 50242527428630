import React, {Component} from 'react';
import TextAndBackground from '../TextAndBackground';
import OpenPositions from '../../pages/common/OpenPositions';
import Container from '../Container';
import './OpenPositions.scss';
class DatabaseDeveloper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(179, 172, 172, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/work/work.jpg"
          imageAlt="CareerLogo">
          <h1>Database Developer</h1>
          <h5 className="banner-header">
            Experience : 5 - 8 years.
            {/* <br /> */}
            {/* Skill Set : Query Optimization, SQL Queries, PLSQL, Denodo, MySQL, Data Virtualization, Data Warehousing, Performance Tuning, Oracle */}
          </h5>
        </TextAndBackground>
        <Container bgColor={'#efefef'}>
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div className="duties-container">
              <p>
                We are looking for mid-level DB developers to work with our client on product enhancements and provide post-go-live production support for users
                in the US, Bangkok, Philippines, Shanghai, and Penang.
              </p>
              <h4>Duties &amp; Responsibilities</h4>
              <ul>
                <li>
                  <strong>
                    <em>Oracle, MySQL experience is preferred</em>
                  </strong>
                </li>
                <li>Strong expertise in PL/SQL</li>
                <li>Must be able to write complex SQL queries by using advanced SQL functions</li>
                <li>Strong expertise in query performance tuning, query optimization, and query debugging</li>
                <li>Good in writing triggers, creating Indexes, views</li>
                <li>Expert understanding of complex concepts of data organization: Expert familiarity with RDBMS</li>
                <li>Experience in the Manufacturing domain is a big plus</li>
                <li>
                  Experience in any{' '}
                  <strong>
                    <em>Data Virtualization</em>
                  </strong>{' '}
                  platform is a big plus
                </li>
                <li>Experience in Tibco Data Virtualization is a huge plus</li>
                <li>Would be responsible for the development of enhancements in the product and support </li>
              </ul>
              <strong>Minimum Qualifications: </strong>
              Any Graduate/Other Post Graduate/Other Doctorate.
              <h4 className="required-header">Required Skills</h4>
              <ul>
                <li>Query Optimization</li>
                <li>SQL Queries</li>
                <li>PLSQL</li>
                <li>Denodo</li>
                <li>MySQL</li>
                <li>Data Virtualization</li>
                <li>Data Warehousing</li>
                <li>Performance Tuning</li>
                <li>Oracle</li>
              </ul>
              <h4>General Skills:</h4>
              <ul>
                <li>Ability to work in a team environment, including cross-functional teams and teams with business users throughout the company.</li>
                <li>Good oral and written communication skills</li>
                <li>Participate in meetings and conference calls with clients.</li>
                <li>Initiative to research and learn emerging technologies.</li>
                <li>Completes additional duties as required.</li>
              </ul>
              <h4>About ContenTerra Software Private Limited</h4>
              <p>
                ContenTerra Software Private Limited is a solutions development company focused on web &amp; mobile application development. We are rapidly
                growing and are looking for passionate people to join our team. ContenTerra is an equal opportunity employer.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="duties-container">
              <p>
                <strong>Application Process: </strong>
                Please send your resume to <a href="mailto:careers@contenterra.com">careers@contenterra.com</a>
              </p>
            </div>
          </div>
        </Container>
        <OpenPositions />
      </div>
    );
  }
}

export default DatabaseDeveloper;
