// import TwoPart from '../../components/TwoPart';
import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import TextAndBackground from '../../components/TextAndBackground';
import Icon from '../../components/Icon';
import Cases from '../../components/Cases';
import IdeaSection from '../common/IdeaSection';
class IOPage extends Component {
  state = {};
  render() {
    var imagesArr = [],
      solutionImagesArr = [];
    for (var i = 1; i < 3; i++) {
      solutionImagesArr.push(`/images/io/mockups/${i}.png`);
    }
    for (i = 1; i < 2; i++) {
      imagesArr.push(`/images/io/mockups/approach_${i}.jpg`);
    }
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(255, 255, 255, 0.01), rgb(132, 129, 126))'}
          imageSrc="/images/io/io.jpg"
          color="white"
          backgroudPosition="100% 19%"
          imageAlt="imageoptimization">
          <h1>ImageOptimization</h1>
          <h3 className="banner-header">How Documentum users compressed images &amp; reduced the storage space by 50-100X</h3>
        </TextAndBackground>
        <TextPart>
          <div>
            <h2>Challenge</h2>
            <p>
              Documentum Administrators regularly revisit the storage requirements on the server due to the growing number of images in the repositories. Users
              face an issue while retrieving images. We identified a need to compress &amp; convert the images to web-optimized and searchable PDF’s to save
              storage space and quick retrieval. We partnered with CVision and used their product PDFCompressor as a compression engine.
            </p>
          </div>
        </TextPart>
        <TwoPartScroller stickRight bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Approach</h2>
            <p>
              We wanted to build a lightweight web application which will be accessed by Documentum Administrators to access the tool and initiate the
              compression jobs.
            </p>
          </div>
        </TwoPartScroller>
        <TwoPartScroller bgColor="#f6faff" imageSrcs={solutionImagesArr} multipleImages={true} imageAlt="images">
          <h2>Solution</h2>
          <p>
            ImageOptimization interfaces Documentum and PDFCompessor. ImageOptimization reads the files from Documentum, compresses and converts based on the
            administrator preferences and saves the files to Documentum. Advanced reporting features are provided to the user.
          </p>
        </TwoPartScroller>

        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>We developed front end using HTML5, CSS3, AngularJS. The backend is developed using Java. We used SQLServer/MYSQL databases.</p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" imageText="AngularJS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/java.png" imageAlt="java" imageText="Java" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/sql.png" imageAlt="spl" imageText="SQL" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>
              Customers saved storage space by a factor of 5-10X in case of Black &amp; White images and by a factor of 50-100X in case of color images which
              translated to a savings of $100k per year for an enterprise customer.
              <br />
              <strong>Foxit Corporation acquired the tool in 2018.</strong>
            </p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default IOPage;
