import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import './about.scss';
import TextAndBackground from '../../components/TextAndBackground';
class AboutPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(rgba(9, 44, 70, 0.96), rgba(56, 169, 244, 0.8))'}
          color="white"
          imageSrc="/images/about/about-us.jpg"
          imageAlt="fedbizintel">
          <h1>About us</h1>
          <h5>
            ContenTerra is an evolving IT Consulting and Support Services firm with proven industry experience in conceptualizing, designing, and implementing
            successful end to end solutions for clients of various sizes from startups to large enterprises.
          </h5>
        </TextAndBackground>
        <div className="container">
          <TextPart>
            <h3>Team</h3>
            <p>
              Our employees are team oriented and share a vision of success. Each team member strives for excellence individually and collectively and are
              experts in specialized subject areas and technologies.
              <br />
              We believe in delivering technology solutions exceeding our client’s expectations and having a positive impact on our client’s business. As a
              team, we work hard and we play hard. The goal is success and the journey is fun.
            </p>
            <h3>Our Process Flow</h3>
            <p>
              Our typical process for developing an application is as follows. Depending on the requirements &amp; type of engagement model, some of the steps
              can be bypassed.
            </p>
            <br />
            <br />
            <ul className="timeline">
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Requirements Gathering &amp; Analysis</p>
                  <p>
                    We discuss your requirements in detail and analyze them on our end. This step goes back and forth until both of us get a clear picture of
                    what is expected.
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Project Plan</p>
                  <p>
                    After understanding the requirements, we will come up with a project plan. We breakdown the project into small pieces with the expected
                    completion date for each task. We strongly believe in a structured way of doing things. Project plan defines that structure and sets the
                    right expectations.
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Wireframes &amp; Prototypes</p>
                  <p>
                    We will come up with wireframes to define the controls, content on each page. We refine the wireframes if necessary based on your inputs. We
                    will show the prototypes based on the wireframes &amp; make necessary changes based on your inputs.
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Design</p>
                  <p>
                    Based on the wireframes, prototypes, and requirements, we will come up with the application design. We will finalize the tools,
                    technologies, &amp; frameworks that we want to use. We will share and discuss it with your team.
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Development</p>
                  <p>
                    We will start the development based on the design. We will schedule periodic demos with you to ensure the application is coming out as you
                    expected. Testing - Our internal QA team will test the application in parallel to development to ensure a top quality deliverable.
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Deploy</p>
                  <p>
                    We will deploy the application on your end for your team to test and report any issues for us to fix. Once your team feels the application
                    is ready, we will help you move it to production.
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Support</p>
                  <p>
                    We will offer support after the application is in production for a limited time. Any issues that are found during the support phase will be
                    fixed by us.
                  </p>
                </div>
              </li>
              <li className="timeline-event">
                <label className="timeline-event-icon" />
                <div className="timeline-event-copy">
                  <p className="timeline-event-thumbnail">Hand off</p>
                  <p>
                    This is the final stage of the engagement. We will conduct knowledge transfer sessions with your team and hand over the application to you.
                  </p>
                </div>
              </li>
            </ul>
          </TextPart>
        </div>
      </div>
    );
  }
}

export default AboutPage;
