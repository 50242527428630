import React, {Component} from 'react';
import HomePage from './pages/home/HomePage';
import NavBar from './components/NavBar/NavBar';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import UberPage from './pages/case-studies/UberPage';
import FedBizintelPage from './pages/case-studies/FedBizintelPage';
import {AnimatedSwitch} from 'react-router-transition';
import ContactPage from './pages/common/ContactPage.';
import AboutPage from './pages/common/AboutPage';
import TwoPart from './components/TwoPart';
import StuworxPage from './pages/case-studies/StuworxPage';
import OurServicesPage from './pages/services/OurServicesPage';
import Page404 from './pages/common/Page404';
import TaskPadPage from './pages/case-studies/TaskPadPage';
import xMonitorPage from './pages/case-studies/xMonitorPage';
import WorkPage from './pages/services/WorkPage';
import TextPart from './components/TextPart';
import FullStackDevelopmentPage from './pages/services/FullStackDevelopmentPage';
import MobileDevelopmentPage from './pages/services/MobileDevelopmentPage';
import MVPBuildingPage from './pages/services/MVPBuildingPage';
import ApplicationMaintenancePage from './pages/services/ApplicationMaintenancePage';
import OffshoreDevelopmentPage from './pages/services/OffshoreDevelopmentPage';
import DocspacePage from './pages/case-studies/DocspacePage';
import IOPage from './pages/case-studies/IOPage';
import TripGain from './pages/case-studies/TripGain';
import PhamaLite from './pages/case-studies/PharmaLite';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CareerPage from './pages/common/CareerPage';
import NodeDeveloper from './components/OpenPositions.js/NodeDeveloper';
import ReactDeveloper from './components/OpenPositions.js/ReactDeveloper';
import AngularJsDeveloper from './components/OpenPositions.js/AngularJsDeveloper';
import SoftwareDeveloperIntern from './components/OpenPositions.js/SoftwareDeveloperIntern';
import TermsAndCondition from './components/TermsAndConditions';
import AnalyticsPage from './pages/services/Analytics';
import DatabaseDeveloper from './components/OpenPositions.js/DatabaseDeveloper';
import DataVirtualization from './pages/services/DataVirtualization';
class App extends Component {
  constructor(props) {
    super(props);
    setTimeout(function() {
      AOS.init({
        once: true
      });
    }, 1000);
  }
  render() {
    return (
      <div>
        <Router>
          <div>
            <NavBar />
            <AnimatedSwitch atEnter={{opacity: 0}} atLeave={{opacity: 0}} atActive={{opacity: 1}}>
              <Route exact path="/" render={() => <HomePage />} />
              <Route path="/case-study/uber" component={UberPage} />
              <Route path="/case-study/fedbizintel" component={FedBizintelPage} />
              <Route path="/case-study/stuworx" component={StuworxPage} />
              <Route path="/case-study/taskpad" component={TaskPadPage} />
              <Route path="/case-study/xmonitor" component={xMonitorPage} />
              <Route path="/case-study/imageoptimization" component={IOPage} />
              <Route path="/case-study/docspace" component={DocspacePage} />
              <Route path="/case-study/tripgain" component={TripGain} />
              <Route path="/case-study/pharmalite" component={PhamaLite} />
              <Route path="/services/full-stack-development" component={FullStackDevelopmentPage} />
              <Route path="/services/mobile-development" component={MobileDevelopmentPage} />
              <Route path="/services/analytics" component={AnalyticsPage} />
              <Route path="/services/mvp-building" component={MVPBuildingPage} />
              <Route path="/services/application-maintenance" component={ApplicationMaintenancePage} />
              <Route path="/services/data-virtualization" component={DataVirtualization} />
              <Route path="/services/offshore-development" component={OffshoreDevelopmentPage} />
              <Route path="/services" component={OurServicesPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/work" component={WorkPage} />
              <Route path="/career/node-developer" component={NodeDeveloper} />
              <Route path="/career/react-developer" component={ReactDeveloper} />
              <Route path="/career/angularjs-developer" component={AngularJsDeveloper} />
              <Route path="/career/software-developer-intern" component={SoftwareDeveloperIntern} />
              <Route path="/career/database-developer" component={DatabaseDeveloper} />
              <Route path="/career" component={CareerPage} />
              <Route path="/terms-and-conditions" component={TermsAndCondition} />
              <Route component={Page404} />
            </AnimatedSwitch>
            <TwoPart backgroundColor="white">
              <React.Fragment>
                <h2 data-aos="fade-up" data-aos-duration="1000">
                  Lets Connect
                </h2>
                <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
                  We look forward to discuss your business needs, and answer any questions you may have. Enter your details and we’ll get back to you shortly.
                </p>
                <div className="row contact-grid-container" data-aos="fade-up" data-aos-duration="1000">
                  <div className="col-lg-12 col-md-12 col-sm-6 col-xs-12">
                    <div className="box">
                      {/* <svg className="icon-standard" viewBox="0 0 24 24">
                        <path
                          fill="#000000"
                          d="M21,5C19.89,4.65 18.67,4.5 17.5,4.5C15.55,4.5 13.45,4.9 12,6C10.55,4.9 8.45,4.5 6.5,4.5C4.55,4.5 2.45,4.9 1,6V20.65C1,20.9 1.25,21.15 1.5,21.15C1.6,21.15 1.65,21.1 1.75,21.1C3.1,20.45 5.05,20 6.5,20C8.45,20 10.55,20.4 12,21.5C13.35,20.65 15.8,20 17.5,20C19.15,20 20.85,20.3 22.25,21.05C22.35,21.1 22.4,21.1 22.5,21.1C22.75,21.1 23,20.85 23,20.6V6C22.4,5.55 21.75,5.25 21,5M21,18.5C19.9,18.15 18.7,18 17.5,18C15.8,18 13.35,18.65 12,19.5V8C13.35,7.15 15.8,6.5 17.5,6.5C18.7,6.5 19.9,6.65 21,7V18.5Z"
                        />
                      </svg> */}
                      <h5 className="grid-title m-b-0">Contact Address</h5>
                      <h4 className="m-t-0 m-b-0">India</h4>
                      <div className="address-details">
                        <p className="grid-subtitle m-b-0">
                          <svg className="contact-icon" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                            />
                          </svg>
                          :{' '}
                          <a className="mailto" href="mailto:support@contenterra.com">
                            support@contenterra.com
                          </a>
                          {/* <br />
                        <svg className="contact-icon" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M16,18H7V4H16M11.5,22A1.5,1.5 0 0,1 10,20.5A1.5,1.5 0 0,1 11.5,19A1.5,1.5 0 0,1 13,20.5A1.5,1.5 0 0,1 11.5,22M15.5,1H7.5A2.5,2.5 0 0,0 5,3.5V20.5A2.5,2.5 0 0,0 7.5,23H15.5A2.5,2.5 0 0,0 18,20.5V3.5A2.5,2.5 0 0,0 15.5,1Z"
                          />
                        </svg>
                        : 9391888398
                        <br />
                        <svg className="contact-icon" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z"
                          />
                        </svg>
                        : Mon To Fri - 8 AM To 5 PM */}
                        </p>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-4">
                            <p className="grid-subtitle d-flex m-b-0">
                              <span>
                                <svg className="contact-icon location-icon" viewBox="0 0 24 24">
                                  <path
                                    fill="currentColor"
                                    d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                                  />
                                </svg>
                              </span>
                              <span className="address">
                                Premier 202, Block A, 2nd floor ,Sri Sai Nagar
                                <br />
                                Madhapur Hyderabad, Telangana -500081, India
                              </span>
                            </p>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-4">
                            {/* <p className="grid-subtitle d-flex">
                            <span>
                              <svg className="contact-icon location-icon" viewBox="0 0 24 24">
                                <path
                                  fill="currentColor"
                                  d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                                />
                              </svg>
                            </span>
                            <span className="address">
                              501, Ace Krishna prime
                              <br />
                              Kothapet, Hyderabad, 500035
                            </span>
                          </p> */}
                            <p className="grid-subtitle d-flex m-b-8">
                              <span>
                                <svg className="contact-icon location-icon" viewBox="0 0 24 24">
                                  <path
                                    fill="currentColor"
                                    d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                                  />
                                </svg>
                              </span>
                              <span className="address">
                                184, Hennuru Village Rd, HBR Layout 4th Block, HBR Layout,
                                <br />
                                Bengaluru, Karnataka 560043
                              </span>
                            </p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" />
                        </div>
                      </div>
                      <h4 className="m-t-0 m-b-0">USA</h4>
                      <div className="address-details">
                        <p className="grid-subtitle m-b-0">Contenterra Software LLC</p>
                        <p className="grid-subtitle m-b-0">
                          <svg className="contact-icon" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                            />
                          </svg>
                          :{' '}
                          <a className="mailto" href="mailto:info@contenterra.com">
                            info@contenterra.com
                          </a>
                          <br />
                          <svg className="contact-icon" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M16,18H7V4H16M11.5,22A1.5,1.5 0 0,1 10,20.5A1.5,1.5 0 0,1 11.5,19A1.5,1.5 0 0,1 13,20.5A1.5,1.5 0 0,1 11.5,22M15.5,1H7.5A2.5,2.5 0 0,0 5,3.5V20.5A2.5,2.5 0 0,0 7.5,23H15.5A2.5,2.5 0 0,0 18,20.5V3.5A2.5,2.5 0 0,0 15.5,1Z"
                            />
                          </svg>
                          : +1(913) 481-3147
                        </p>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <p className="grid-subtitle d-flex">
                              <span>
                                <svg className="contact-icon location-icon" viewBox="0 0 24 24">
                                  <path
                                    fill="currentColor"
                                    d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z"
                                  />
                                </svg>
                              </span>
                              <span className="address">
                                15604 Prestancia Dr,
                                <br />
                                Austin, TX 78717, USA
                              </span>
                            </p>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="box">
                      <svg className="icon-standard lets-talk" viewBox="0 0 24 24">
                        <path
                          fill="#000000"
                          d="M9.5,4C5.36,4 2,6.69 2,10C2,11.89 3.08,13.56 4.78,14.66L4,17L6.5,15.5C7.39,15.81 8.37,16 9.41,16C9.15,15.37 9,14.7 9,14C9,10.69 12.13,8 16,8C16.19,8 16.38,8 16.56,8.03C15.54,5.69 12.78,4 9.5,4M6.5,6.5A1,1 0 0,1 7.5,7.5A1,1 0 0,1 6.5,8.5A1,1 0 0,1 5.5,7.5A1,1 0 0,1 6.5,6.5M11.5,6.5A1,1 0 0,1 12.5,7.5A1,1 0 0,1 11.5,8.5A1,1 0 0,1 10.5,7.5A1,1 0 0,1 11.5,6.5M16,9C12.69,9 10,11.24 10,14C10,16.76 12.69,19 16,19C16.67,19 17.31,18.92 17.91,18.75L20,20L19.38,18.13C20.95,17.22 22,15.71 22,14C22,11.24 19.31,9 16,9M14,11.5A1,1 0 0,1 15,12.5A1,1 0 0,1 14,13.5A1,1 0 0,1 13,12.5A1,1 0 0,1 14,11.5M18,11.5A1,1 0 0,1 19,12.5A1,1 0 0,1 18,13.5A1,1 0 0,1 17,12.5A1,1 0 0,1 18,11.5Z"
                        />
                      </svg>
                      <h4 className="grid-title">Lets Talk</h4>
                      <p className="grid-subtitle">
                        Phone: 9391888398
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="box">
                      <svg className="icon-standard" viewBox="0 0 24 24">
                        <path
                          fill="#000000"
                          d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
                        />
                      </svg>
                      <h4 className="grid-title">Email us</h4>
                      <p className="grid-subtitle">
                        <a className="mailto" href="mailto:support@contenterra.com">
                          support@contenterra.com
                        </a>
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="box">
                      <svg className="icon-standard" viewBox="0 0 24 24">
                        <path
                          fill="#000000"
                          d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z"
                        />
                      </svg>
                      <h4 className="grid-title">Working Hours</h4>
                      <p className="grid-subtitle">Mon To Fri - 8 AM To 5 PM</p>
                    </div>
                  </div> */}
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className="row" id="contact_us" data-aos="fade-up" data-aos-duration="1000">
                  <ContactPage hideHeadings nopadding />
                </div>
              </React.Fragment>
            </TwoPart>
            <TwoPart footer bgColor="black" className="footer" color="white" data-aos="fade-up" data-aos-duration="100">
              <label>
                <span style={{verticalAlign: 'sub'}}>©</span> {new Date().getFullYear()} Contenterra, All Rights Reserved.
              </label>
              <a className="terms-conditions-link" href="/terms-and-conditions">
                Terms and conditions
              </a>
            </TwoPart>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
