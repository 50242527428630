import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import Cases from '../../components/Cases';
import TextAndBackground from '../../components/TextAndBackground';
import Icon from '../../components/Icon';
import IdeaSection from '../common/IdeaSection';

class TaskPadPage extends Component {
  state = {};
  render() {
    var imagesArr = [],
      solutionImagesArr = [];
    for (var i = 1; i < 4; i++) {
      solutionImagesArr.push(`/images/taskpad/Mockup/sol_${i}.jpg`);
    }
    for (i = 1; i < 3; i++) {
      imagesArr.push(`/images/taskpad/Mockup/approach_${i}.jpg`);
    }
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(255, 255, 255, 0.01), rgba(103, 103, 103, 0.74))'}
          color="white"
          imageSrc="/images/taskpad/taskpad.jpg"
          imageAlt="taskpad"
          backgroudPosition="center">
          <h1>TaskPad</h1>
          <h3 className="banner-header">How we revamped an existing task management application to improve the install base</h3>
        </TextAndBackground>
        {/* <TextPart>
          <h2>Eisenhower&#39;s Matrix</h2>
          <p>
            Using the Eisenhower Decision Principle, tasks are evaluated using the criteria important/unimportant and urgent/not urgent, and then placed in
            according quadrants in an Eisenhower Matrix (also known as an &#34;Eisenhower Box&#34; or &#34;Eisenhower Decision Matrix&#34;)
          </p>
        </TextPart> */}
        <TextPart>
          <h2>Challenge</h2>
          <p>
            Not all tasks are the same and doing the right things is the key for users. Taskpad is an existing app on Google Playstore built based on
            Eisenhower’s productivity matrix. Taskpad team reached out to us to revamp the current application by modifying the user interface and adding more
            functionality.
          </p>
        </TextPart>
        <TwoPartScroller stickRight bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <h2>Approach</h2>
          <p>
            We analyzed the existing application and identified that the currentapplication hasn’t been updated and is using outdated API’s & styling. After
            discussing with Taskpad team, we have finalized our primary focus areas which helps differentiate the updated app with existing ones in the market.
            The primary focus was to help users become more productive and secondary focus areas were leverage cloud infrastructure & support multiple devices,
            clean UI, include analytics, user reports, and sync tasks with google calendar.
          </p>
        </TwoPartScroller>
        <TwoPartScroller bgColor="#f6faff" imageSrcs={solutionImagesArr} multipleImages={true} imageAlt="images">
          <h2>Solution</h2>
          <p>
            Taskpad provides an intuitive & straightforward UI for users to create tasks and organize them into roles and folders. Users will be able to
            collaborate with each other by sharing tasks or delegating. To help users to complete the tasks, google calendar integration is provided which
            automatically adds the tasks to calendar. All the data is storedin the cloud, and multiple devices are supported. We have also implemented a web
            version with the same feature set.
          </p>
        </TwoPartScroller>
        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>
                We used firebase for storing user data and analytics. We implemented a custom calendar view in native android that syncs with google calendar.
                For the web version, we have used HTML5, CSS3, and AngularJS.
              </p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angular" imageText="Angular" />
                </div>
                {/* <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/md.png" imageAlt="materialdesign" imageText="Material Design" />
                </div> */}
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/firebase.png" imageAlt="firebase" imageText="Firebase" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/android.png" imageAlt="android" imageText="Android" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>Taskpad is published in Playstore and has more than 15000 downloads.</p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default TaskPadPage;
