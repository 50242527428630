import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import './../services/Services.scss';
import {NavLink} from 'react-router-dom';
import Icon from '../../components/Icon';
class OpenPositions extends Component {
  render() {
    this.serviceStyles = {
      title: {}
    };
    return (
      <TextPart nopadding={true} center bgColor="#f5faff">
        <h3 data-aos="fade-up">We have the following open positions</h3>
        <div className="boxx" data-aos="fade-up">
          <div className="row technologies">
            <NavLink to="/career/node-developer" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Icon imageSrc="/images/icons/node.png" imageAlt="node" />
              <h5>NODE DEVELOPER</h5>
            </NavLink>
            <NavLink to="/career/react-developer" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Icon imageSrc="/images/icons/react.png" imageAlt="react" />
              <h5>REACT DEVELOPER</h5>
            </NavLink>
            <NavLink to="/career/angularjs-developer" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" />
              <h5>ANGULAR JS DEVELOPER</h5>
            </NavLink>

            <NavLink to="/career/software-developer-intern" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <svg className="icon-standard software-intern-icon" fill="#888" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="orangered"
                  d="M20,18V14H17V15H15V14H9V15H7V14H4V18H20M6.33,8L4.59,12H7V11H9V12H15V11H17V12H19.41L17.67,8H6.33M9,5V6H15V5H9M21.84,12.61C21.94,12.83 22,13.09 22,13.41V18C22,18.53 21.79,19 21.4,19.41C21,19.81 20.55,20 20,20H4C3.45,20 3,19.81 2.6,19.41C2.21,19 2,18.53 2,18V13.41C2,13.09 2.06,12.83 2.16,12.61L4.5,7.22C4.84,6.41 5.45,6 6.33,6H7V5C7,4.45 7.18,4 7.57,3.59C7.96,3.2 8.44,3 9,3H15C15.56,3 16.04,3.2 16.43,3.59C16.82,4 17,4.45 17,5V6H17.67C18.55,6 19.16,6.41 19.5,7.22L21.84,12.61Z"
                />
              </svg>
              <h5>Software Developer Intern</h5>
            </NavLink>
            <NavLink to="/career/database-developer" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <svg className="icon-standard software-intern-icon" fill="#888" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z"
                />
              </svg>
              <h5>Database Developer</h5>
            </NavLink>
          </div>
        </div>
      </TextPart>
    );
  }
}

export default OpenPositions;
