import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import './Services.scss';
import {NavLink} from 'react-router-dom';
import Icon from '../../components/Icon';
class AllServices extends Component {
  render() {
    this.serviceStyles = {
      title: {}
    };
    return (
      <React.Fragment>
        <TextPart nopadding={true} center bgColor="#f5faff">
          <h3 data-aos="fade-up">Our Services</h3>
          <div className="boxx" data-aos="fade-up">
            <div className="row">
              <NavLink to="/services/analytics" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <img style={{width: 64, marginBottom: 16}} src="/images/services/analytics-icon-1.jpg" alt="analytics" />
                <h5 style={this.serviceStyles.title}>Analytics</h5>
              </NavLink>
              <NavLink to="/services/full-stack-development" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <svg className="icon-standard" fill="#888" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path fill="red" d="M12 2l-5.5 9h11z" />
                  <circle fill="green" cx="17.5" cy="17.5" r="4.5" />
                  <path fill="dodgerblue" d="M3 13.5h8v8H3z" />
                </svg>
                <h5 style={this.serviceStyles.title}>Full Stack Development</h5>
              </NavLink>
              <NavLink to="/services/mobile-development" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <svg className="icon-standard" fill="indigo" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M19 1H9c-1.1 0-2 .9-2 2v3h2V4h10v16H9v-2H7v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zM7.01 13.47l-2.55-2.55-1.27 1.27L7 16l7.19-7.19-1.27-1.27z" />
                  <path fill="none" d="M0 0h24v24H0z" />
                </svg>
                <h5 style={this.serviceStyles.title}>Mobile Development</h5>
              </NavLink>
              {/* <NavLink to="/services/mvp-building" className="service-block col-lg-4 col-md-4 col-sm-6 col-xs-12">
              <svg className="icon-standard" fill="#888" width="24" height="24" viewBox="0 0 24 24">
                <path
                  fill="teal"
                  d="M22,12V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V12A1,1 0 0,1 1,11V8A2,2 0 0,1 3,6H6.17C6.06,5.69 6,5.35 6,5A3,3 0 0,1 9,2C10,2 10.88,2.5 11.43,3.24V3.23L12,4L12.57,3.23V3.24C13.12,2.5 14,2 15,2A3,3 0 0,1 18,5C18,5.35 17.94,5.69 17.83,6H21A2,2 0 0,1 23,8V11A1,1 0 0,1 22,12M4,20H11V12H4V20M20,20V12H13V20H20M9,4A1,1 0 0,0 8,5A1,1 0 0,0 9,6A1,1 0 0,0 10,5A1,1 0 0,0 9,4M15,4A1,1 0 0,0 14,5A1,1 0 0,0 15,6A1,1 0 0,0 16,5A1,1 0 0,0 15,4M3,8V10H11V8H3M13,8V10H21V8H13Z"
                />
              </svg>
              <h5 style={this.serviceStyles.title}>MVP Building</h5>
            </NavLink> */}

              <NavLink to="/services/offshore-development" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <svg className="icon-standard" fill="#888" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    fill="green"
                    d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                  />
                </svg>
                <h5 style={this.serviceStyles.title}>Offshore Development</h5>
              </NavLink>
              <NavLink to="/services/application-maintenance" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <svg className="icon-standard" fill="#888" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    fill="orangered"
                    d="M20,18V14H17V15H15V14H9V15H7V14H4V18H20M6.33,8L4.59,12H7V11H9V12H15V11H17V12H19.41L17.67,8H6.33M9,5V6H15V5H9M21.84,12.61C21.94,12.83 22,13.09 22,13.41V18C22,18.53 21.79,19 21.4,19.41C21,19.81 20.55,20 20,20H4C3.45,20 3,19.81 2.6,19.41C2.21,19 2,18.53 2,18V13.41C2,13.09 2.06,12.83 2.16,12.61L4.5,7.22C4.84,6.41 5.45,6 6.33,6H7V5C7,4.45 7.18,4 7.57,3.59C7.96,3.2 8.44,3 9,3H15C15.56,3 16.04,3.2 16.43,3.59C16.82,4 17,4.45 17,5V6H17.67C18.55,6 19.16,6.41 19.5,7.22L21.84,12.61Z"
                  />
                </svg>
                <h5 style={this.serviceStyles.title}>Software Maintenance</h5>
              </NavLink>
              <NavLink to="/services/data-virtualization" className="service-block col-lg-3 col-md-4 col-sm-6 col-xs-12">
                <img style={{height: 74}} src="/images/services/data-virtualization-icon.png" alt="data virtualization" />
                <h5 style={this.serviceStyles.title}>Data Virtualization</h5>
              </NavLink>
            </div>
          </div>
        </TextPart>
        <TextPart center={true} nopadding={true}>
          <div>
            <h3 data-aos="fade-up" data-aos-duration="1000">
              We worked with
            </h3>
            <div data-aos="fade-up" data-aos-duration="1000">
              <br />
              <div className="row technologies worked-companies">
                {[
                  {
                    imageLink: 'wd.png'
                  },
                  {
                    imageLink: 'its.png'
                  },
                  {
                    imageLink: 'liberty-mutual.png',
                    height: 140
                  },
                  {
                    imageLink: 'kennedy-krieger.jpg',
                    height: 100
                  },
                  {
                    imageLink: 'macys.png'
                  },
                  {
                    imageLink: 'bridgestone.png',
                    height: 50
                  },
                  {
                    imageLink: 'novartis.png',
                    height: 100
                  },
                  {
                    imageLink: 'pg-e.png',
                    height: 100
                  },
                  {
                    imageLink: 'ge-power.png',
                    height: 120
                  },
                  {
                    imageLink: 'department-environment.png',
                    height: 150
                  },
                  {
                    imageLink: 'nyse.png'
                  },
                  {
                    imageLink: 'fpl.jpg'
                  },
                  {
                    imageLink: 'verizon.png',
                    height: 50
                  },
                  {
                    imageLink: 'ford.png',
                    height: 100
                  },
                  {
                    imageLink: 'thermo-fisher.png',
                    height: 50
                  },
                  {
                    imageLink: 'sobi.jpg'
                  },
                  {
                    imageLink: 'combined-insurence.png',
                    height: 150
                  },
                  {
                    imageLink: 'fiserve.png',
                    height: 60
                  },
                  {
                    imageLink: 'roche.jpg'
                  },
                  {
                    imageLink: 'mcguire-woods.png',
                    height: 100
                  },
                  {
                    imageLink: 'bhpbilliton.png'
                  },
                  {
                    imageLink: 'razorpay.png',
                    height: 120
                  },
                  {
                    imageLink: 'nbc.png',
                    height: 100
                  },
                  {
                    imageLink: 'stt.jpg',
                    height: 100
                  }
                ].map((image, imageIndex) => (
                  <div key={imageIndex} className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                    <Icon height={image.height} imageSrc={`/images/worked-logos/${image.imageLink}`} imageAlt="html" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </TextPart>
      </React.Fragment>
    );
  }
}

export default AllServices;
