import React, {Component} from 'react';
import TextPart from './TextPart';
import './../styles/tnc.scss';
class TermsAndCondition extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <TextPart bgColor="#f4f4f4">
          <h3 data-aos="fade-up" data-aos-duration="1000">
            For Software Development and Consulting services
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            This Service Agreement (“Agreement”) shall apply and govern the Statement of Work(s), project, letter of intent or any other document (“SOW”)
            executed between Contenterra Software Pvt. Limited or any of its affiliates [specifically identified in the SOW] (“Consultant/Consultant”) and
            Customer, for the purpose of providing professional services (“Services”) or deliverables (“Deliverable”) for software development and consulting.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Payments:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Payment will be made by Customer within 15 days upon receipt of an invoice. In the event there is a delay in payment for more than 5 days from the
            due date, the Customer shall be liable to pay an interest of 1.5% per month or maximum permitted by applicable law, whichever is less, on the
            delayed payments from the due date of payment. Consultant shall be relieved of its obligations under this Agreement in the event of non-payment of
            the Fees or expenses due and shall retain the rights in the Services for which the amount is outstanding. Consultant will provide the Hardware and
            Software stated in Annexure – 01, as part of its standard package if required while providing the offshore Services from Consultant’s location(s) in
            India. Contractor’s relationship with Company is that of an independent contractor, and nothing in this Agreement will be construed to create a
            joint partnership, joint venture, agency, or employer-employee relationship.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Approval Process:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Customer will have seven (7) days following receipt of the Services or Deliverable (“Acceptance Period”), to complete acceptance tests as per
            acceptance criteria agreed in the SOW (“Acceptance Criteria”). If no notice of non-conformance to Acceptance Criteria is reported during Acceptance
            Period, Deliverables or Services are deemed accepted by the Customer.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Confidentiality:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Confidential information shall mean any information disclosed by one party to the other party, in any form including without limitation documents,
            business plans, source code, software, technical/ financial/ marketing/ customer/ business information, specifications, analysis, designs, drawings,
            data, computer programs, any information relating to personnel or Affiliates of a party and include information disclosed by third parties at the
            direction of a Disclosing Party and marked as confidential within 15 days of such disclosure. Confidential Information shall however, exclude any
            information which (i) is/ was publicly known or comes into public domain; (ii) is received by the Receiving Party from a third party, without breach
            of this Agreement; (iii) was already in the possession of Receiving Party, without confidentiality restrictions, at the time of disclosure by the
            Disclosing Party; (iv) is permitted for disclosure by the Disclosing Party in writing; (v) independently developed by the Receiving Party without
            use of Confidential Information; (vi) is required to be disclosed by the Receiving Party pursuant to any order or requirement from court,
            administrative or governmental agency, provided that the Receiving Party shall give the Disclosing Party prompt written notice of such order or
            requirement and an opportunity to contest or seek an appropriate protective order. The Receiving Party agrees not to use any Confidential
            Information for any purpose except for conducting business with the Disclosing Party, or otherwise agreed in writing.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Intellectual Property Rights:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Customer shall own all right, title and interest in and to the Deliverables. The rights, title and interest in and to the Deliverables shall be
            granted to the Customer only upon receipt of full payment by the Consultant. To the extent that the Deliverables incorporates Consultant
            pre-existing intellectual property (“Consultant Pre-existing IP”), and such Consultant Pre-Existing IP are necessarily required for the proper
            functioning of the Deliverables Consultant grants to Customer a perpetual, non-exclusive, worldwide, transferable, royalty-free license to use such
            Consultant Pre-Existing IP solely along with the Deliverables.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Warranties:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Except as expressly stated in this Agreement, the parties disclaim all warranties of any kind, implied, statutory, or in any communication between
            them, including without limitation, the implied warranties of merchantability, non-infringement, title, and fitness for a particular purpose.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Limitation of Liability:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            The total liability of the parties under this Agreement (whether in contract, tort (including negligence)) shall not exceed the fees paid to
            Consultant hereunder. The parties disclaim any indirect, special, consequential or incidental damages or loss of revenue or business profits,
            however caused, even if advised of the possibility of such damages. The foregoing limitations of liability will apply notwithstanding the failure of
            essential purpose of any limited remedy herein.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Termination:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Either party may terminate the Agreement upon sixty (60) days’ notice to the other party. Either party may terminate this Agreement immediately if
            the other party breaches the terms of this Agreement and the breach remain uncured for 30 days from the date of receipt of notice. In case of
            termination, the Consultant shall be paid for the Services provided on a pro-rata basis.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Non-Hire and Non-Solicitation:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            During the term of this Agreement and for one (1) year thereafter, neither party will directly or indirectly recruit, solicit or induce any
            personnel, Consultant or advisor of the other party to terminate his or her relationship with such other party without the prior written permission
            of the other party.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Force Majeure:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Neither party shall be liable for any failure or delay in fulfilling the terms of this Agreement due to fire, strike, war, civil unrest, terrorist
            action, government regulations, act of Nature or other causes which are unavoidable and beyond the reasonable control of the party claiming force
            majeure. This provision shall not be construed as relieving either party from its obligation to pay any sum due to the other party.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Subcontracting:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Consultant may subcontract the services to any of its affiliates.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Governing Law and Dispute Resolution:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            These Terms and Conditions shall be governed by and construed in accordance with the laws of India. Each party irrevocably consents to the exclusive
            jurisdiction, forum and venue of the Courts situated at City of Hyderabad, TELAGANA STATE, INDIA.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Entire Agreement:
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            This Agreement shall constitute the complete agreement between the parties respecting the subject matter. This Agreement may not be extended,
            amended, terminated, or superseded except by agreement in writing between the parties. This Agreement supersedes all previous agreements between the
            Consultant and the Customer, whether oral or written, regarding subject matter hereof, standard terms and conditions of a purchase order or an
            invoice or any similar document whether hosted on party’s website or otherwise, shall be ineffective. There are no intended third party
            beneficiaries to this Agreement. Each Agreement may be executed in one or more counterparts (including scanned copies), all of which when signed and
            taken together constitute a single agreement between the parties.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Annexure-01
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            Standard Hardware and Software for Development and Testing
            <br />
            Consultant will provide the following Hardware and Software as part of its standard package if required for offshore services. In case any Hardware
            / Software are required in addition to this the same shall be paid by Customer.
          </p>
          <h3 data-aos="fade-up" data-aos-duration="1000">
            Refund Policy
          </h3>
          <p className="contact-subtitle" data-aos="fade-up" data-aos-duration="1000">
            If you are not satisfied with any purchase from Contenterra and would like a refund, please reach out to{' '}
            <a href="mailto:support@contenterra.com">support@contenterra.com</a> and initiate a refund request. Contenterra team will carefully analyze each
            refund case and make a decision. The decision is purely at the discretion of the Contenterra team. Please note that any refund requests for the
            purchases which are more than 30 days old will not be entertained. Refunds will be processed within 5-10 business days and you will receive a
            confirmation mail. The funds will be deposited to your original payment method.
          </p>

          <h3 data-aos="fade-up" data-aos-duration="1000">
            Standard Hardware and Software:
          </h3>
          <div>
            <table className="tnc-table" data-aos="fade-up" data-aos-duration="1000">
              <tbody>
                <tr>
                  <th className="tnc-header">Desktop Hardware</th>
                  <td>Core i5 2.x GHz, 8 GB RAM, 1GB LAN Card, 500 GB HDD, 17 Inch TFT Color monitor (only with Desktop), Keyboard, 3 button scroll mouse.</td>
                </tr>
                <tr>
                  <th className="tnc-header">Laptop</th>
                  <td>Core i5 2.x GHz, 8 GB RAM, 1GB LAN Card, 500 GB HDD, 17 Inch TFT Color monitor (only with Desktop), Keyboard, 3 button scroll mouse.</td>
                </tr>
                <tr>
                  <th className="tnc-header">Desktop/Laptop Software</th>
                  <td>
                    MS Windows OS, Antivirus- Corporate Edition, Microsoft Office, Disk Encryption Tool and DLP (only for Laptop), content filtering client,
                    Asset Management Agent
                  </td>
                </tr>
                <tr>
                  <th className="tnc-header">Asset Ratio</th>
                  <td>As per organization policy</td>
                </tr>
                <tr>
                  <th className="tnc-header">Test Virtual Machine</th>
                  <td>
                    Virtual Machine with 2 core 4 GB RAM and 80 GB HDD.
                    <p />
                    <p>
                      Only in case of Physical devices to be connected to the machine, a physical machine will be provided with below configuration( Only in
                      case of exception)
                    </p>
                    <p>Core i5 2. X GHz, 8 GB RAM, 1GB LAN Card, 500 GB HDD, 17 Inch TFT Color monitor, Keyboard, 3 button scroll mouse</p>
                  </td>
                </tr>
                <tr>
                  <th className="tnc-header">Test VM/Machine Software</th>
                  <td>MS Windows OS depending on the testing platform, Antivirus -Corporate Edition</td>
                </tr>
                <tr>
                  <th className="tnc-header">Dev/Test VM Machine Ratio</th>
                  <td>1 VM per Developer AWS &amp; AZURE( on paid basis )</td>
                </tr>
                <tr>
                  <th className="tnc-header">Software Tools (for testing)</th>
                  <td>IBM Rational, Jira, MSDN Pro</td>
                </tr>
              </tbody>
            </table>
          </div>
        </TextPart>
      </React.Fragment>
    );
  }
}

export default TermsAndCondition;
