import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TwoPart extends PureComponent {
  state = {};
  constructor(props) {
    super(props);
    this.partStyle = {
      backgroundColor: this.props.bgColor || 'white',
      color: this.props.color || 'black',
      backgroundImage: `url(${this.props.imageSrc})`
    };
  }
  render() {
    return (
      <div style={this.partStyle} data-aos="fade-up" data-aos-duration="1000">
        <div className="container">
          <div className="boxx">
            <div className="row">
              {this.props.children &&
                this.props.children.map((x, i) => {
                  return (
                    <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="box">{x}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
TwoPart.propTypes = {
  imageSrc: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  imageAlt: PropTypes.string,
  blendWithNavBar: PropTypes.bool
};
export default TwoPart;
