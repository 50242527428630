import React, {Component} from 'react';
import TextPart from '../../components/TextPart';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
import Cases from '../../components/Cases';
import TextAndBackground from '../../components/TextAndBackground';
import Icon from '../../components/Icon';
import IdeaSection from '../common/IdeaSection';

class xMonitorPage extends Component {
  state = {};
  render() {
    var imagesArr = [],
      solutionsArray = [];
    for (var i = 1; i < 3; i++) {
      imagesArr.push(`/images/xmonitor/mockups/${i}.jpg`);
    }
    for (i = 4; i < 6; i++) {
      solutionsArray.push(`/images/xmonitor/mockups/${i}.png`);
    }
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          gradient={'linear-gradient(45deg, rgba(255, 255, 255, 0.01), rgba(0, 0, 0, 0.74))'}
          color="white"
          imageSrc="/images/xmonitor/xMonitor.jpg"
          imageAlt="xmonitor">
          <h1>xMonitor</h1>
          <h3 className="banner-header">How we reduced the monitoring effort of ECM environments by 60%</h3>
        </TextAndBackground>
        <TextPart>
          <h2>Challenge:</h2>
          <p>
            Many organizations experience problems while performing business-critical content management processes. End users often become aware of these
            problems even before the IT department gets a whiff of the issues. Calls to the support desk are usually the only means of alerting IT and reflect a
            huge loss in productivity in both time and resources. We saw a need for a real time proactive monitoring solution that lets Administrators identify
            the issue before users report thereby improving the overall productivity.
          </p>
        </TextPart>
        <TwoPartScroller stickRight stic bgColor="#f6faff" imageSrcs={imagesArr} multipleImages={true} imageAlt="images">
          <div>
            <h2>Approach</h2>
            <p>
              When we talked to administrators, we realized that the top expectations were proactive monitoring and timely notification when an error occurs on
              any of the servers being monitored. The other consideration for us is, in an enterprise environment where all the servers are not in the same
              location, the network performance becomes a key. We had to build a system to account for fluctuations in the network performance.
            </p>
          </div>
        </TwoPartScroller>
        <TwoPartScroller imageSrcs={solutionsArray} bgColor="#f6faff" multipleImages={true} imageAlt="images">
          <div>
            <h2>Solution</h2>
            <p>
              xMonitor will allow users to add servers to the monitoring list & configure the monitoring options via a simple web interface. xMonitor allows
              monitoring agents to be deployed on remote servers to overcome the network delay. Error logs are stored in a database and are aggregated into a
              simple dashboard. Email notifications are sent to the users depending on the severity of the error.
            </p>
          </div>
        </TwoPartScroller>
        <TextPart>
          <div>
            <h2>Technology</h2>
            <div>
              <p>We developed front end using HTML5, CSS3, AngularJS. The backend is developed using .NET, Java. We used SQLServer for storing the data.</p>
              <br />
              <div className="row technologies">
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/html.png" imageAlt="html" imageText="HTML5" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/css.png" imageAlt="css" imageText="CSS3" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/angularjs.png" imageAlt="angularjs" imageText="AngularJS" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/aspnet.png" imageAlt="asp" imageText="ASP.NET MVC" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/java.png" imageAlt="java" imageText="Java" />
                </div>
                <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
                  <Icon imageSrc="/images/icons/sql.png" imageAlt="spl" imageText="SQL" />
                </div>
              </div>
            </div>
          </div>
        </TextPart>
        <TextPart>
          <div>
            <h2>Result</h2>
            <p>xMonitor was sold to enterprise Captiva customers. Administrators saved time and effort in maintaining the environment.</p>
          </div>
        </TextPart>
        <IdeaSection />
        <Cases />
      </div>
    );
  }
}

export default xMonitorPage;
