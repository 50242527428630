import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import TextPart from '../../components/TextPart';
import OpenPositions from './OpenPositions';
class CareerPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(179, 172, 172, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/work/work.jpg"
          imageAlt="CareerLogo">
          <h1>Career</h1>
          <h3 className="banner-header">
            Join our team. Your opportunities for growth are limitless. You’ll work with, and learn from, the best and brightest in their fields, and they’ll
            learn from you.
          </h3>
        </TextAndBackground>
        <TextPart>
          <p>
            We are looking for someone who enjoys overcoming challenges, and who gets satisfaction from doing what others thought couldn’t be done. If you’re
            ready to push/test the limits of your potential and do extraordinary things, you belong on our team.
          </p>
        </TextPart>
        <OpenPositions />
      </div>
    );
  }
}

export default CareerPage;
