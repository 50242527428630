import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import TextPart from '../../components/TextPart';
import Icon from '../../components/Icon';
import WhyCooseUs from '../../components/WhyChoseUs';
class AnalyticsPage extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies analytics-page">
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          class="analytics-hero-image"
          gradient={'linear-gradient(45deg, rgba(191, 185, 185, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/services/analytics.jpg"
          imageAlt="mobile">
          <h1>Data Analytics Services</h1>
          <h3>Gain actionable insights from data that help you make informed business decisions</h3>
        </TextAndBackground>
        <TextPart>
          <p>
            We help our customers to deeply understand the data and derive actionable insights through data visualizations, comprehensive dashboards, and custom
            analytic models. Our goal is to provide key critical and consumable information to decision makers. Our team is experienced in analytic tools like
            <strong> IBM Cognos, Tableau, PowerBI, ThoughSpot, SpotFire</strong>, and IBM Watson platform. Our team’s strong foundational expertise in
            traditional RDBMS like <strong>Oracle, SQL Server&nbsp;</strong>
            and NOSQL Databases like <strong>MongoDB, ElasticSearch, Solr, and RedShift&nbsp;</strong>
            helps to efficiently build analytical solutions across different platforms.
          </p>
          <p>Our experienced team shall categorize the requirements into one of the following types of analytics and use tools/frameworks accordingly.</p>
          <p>
            <strong>Descriptive Analytics: </strong>
            Create a snapshot of what the data entails using BI tools.
          </p>
          <p>
            <strong>Diagnostic Analytics: </strong>
            Identify the issue and the source of it by deeper investigation of the patterns in the data.
          </p>
          <p>
            <strong>Predictive Analytics: </strong>
            Predict a possible outcome by building custom AI/ML models
          </p>

          <br />
          <div className="row technologies">
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/ibm-cognos.png" imageAlt="ibm-cognos" imageText="IBM Cognos" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/tableau.png" imageAlt="tableau" imageText="Tableau" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/power-bi.png" imageAlt="power-bi" imageText="PowerBI" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/thought-spot.jpg" imageAlt="though-spot" imageText="ThoughSpot" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/spot-fire.jpg" imageAlt="spot-fire" imageText="SpotFire" />
            </div>

            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/oracle.png" imageAlt="spl" imageText="Oracle" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/sql.png" imageAlt="spl" imageText="SQL Server" />
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/mongodb.png" imageAlt="mongodb" imageText="MongoDB" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/elasticsearch.png" imageAlt="elasticsearch" imageText="Elasticsearch" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/solr.png" imageAlt="solr" imageText="Solr" />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
              <Icon imageSrc="/images/icons/redshift.png" imageAlt="redshift" imageText="RedShift" />
            </div>
          </div>
        </TextPart>

        <WhyCooseUs />
      </div>
    );
  }
}

export default AnalyticsPage;
