import React, {Component} from 'react';
import TextAndBackground from '../TextAndBackground';
import OpenPositions from '../../pages/common/OpenPositions';
import Container from '../Container';
import './OpenPositions.scss';
class NodeDeveloper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(45deg, rgba(179, 172, 172, 0.7), rgba(80, 80, 80, 0.74))'}
          imageSrc="/images/work/work.jpg"
          imageAlt="CareerLogo">
          <h1>NODE DEVELOPER</h1>
          <h5 className="banner-header">
            Experience : 0 – 2 years.
            <br />
            Skill Set : JavaScript, Node.js, MongoDB, MYSQL,Typescript
          </h5>
        </TextAndBackground>
        <Container bgColor={'#efefef'}>
          <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
            <div className="duties-container">
              <p>
                We are looking for a few backend developers proficient in developing applications using Node.js to join our development team. The candidate will
                work with our development team on our existing products and projects.
              </p>
              <h4>Duties &amp; Responsibilities</h4>
              <ul>
                <li>Integration of user-facing elements developed by front-end developers with server-side logic</li>
                <li>Writing reusable, testable, and efficient code</li>
                <li>Design and implementation of low-latency, high-availability, and performant applications</li>
                <li>Implementation of security and data protection</li>
                <li>Integration of data storage solutions (key-value stores, blob stores, etc.)</li>
                <li>Data Storage and Data Access from Database systems such as MongoDB, MYSQL, etc.</li>
              </ul>
              <h4>Required Skills</h4>
              <ul>
                <li>Strong proficiency with JavaScript</li>
                <li>Strong web fundamentals</li>
                <li>Knowledge of Node.js and its associated frameworks such as Express, mongoose</li>
                <li>Understanding the nature of asynchronous programming and its quirks and workarounds</li>
                <li>Knowledge of ES6 functional modules and APIs</li>
                <li>User authentication and authorization between multiple systems, servers, and environments</li>
                <li>Integration of various data sources and databases into one system</li>
                <li>Understanding fundamental design principles behind a scalable application</li>
                <li>
                  Understanding differences between different delivery platforms, such as mobile vs. desktop, and optimizing output to match the specific
                  platform
                </li>
                <li>Creating database schemas that represent and support business processes</li>
                <li>Proficient understanding of code versioning tools, such as Git</li>
              </ul>
              <h4>Good to Have Skills</h4>
              <ul>
                <li>Good Understanding of Typescript</li>
                <li>Capability to deploy node.js applications on multiple platforms (AWS, Azure, etc.)</li>
                <li>Good Understanding of MongoDB is a huge plus</li>
                <li>Knowledge in ElasticSearch & Redis is a plus</li>
              </ul>
              <h4>General Skills:</h4>
              <ul>
                <li>Ability to work in a team environment, including cross-functional teams and teams with business users throughout the company.</li>
                <li>Good oral and written communication skills</li>
                <li>Participate in meetings and conference calls with clients.</li>
                <li>Initiative to research and learn emerging technologies.</li>
                <li>Completes additional duties as required.</li>
              </ul>
              <h4>About ContenTerra Software Private Limited</h4>
              <p>
                ContenTerra Software Private Limited is a solutions development company focused on web &amp; mobile application development. We are rapidly
                growing and are looking for passionate people to join our team. ContenTerra is an equal opportunity employer.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <div className="duties-container">
              {/* <p>
                <strong>Experience: </strong>0 – 2 years.
              </p>
              <p className="skills">
                <strong>Skill Set: </strong>
                <span>JavaScript</span>
                <span>Node.js</span>
                <span>MongoDB</span>
                <span>MYSQL</span>
                <span>Typescript</span>
              </p> */}
              <p>
                <strong>Application Process: </strong>
                Please send your resume to <a href="mailto:careers@contenterra.com">careers@contenterra.com</a>
              </p>
            </div>
          </div>
        </Container>
        <OpenPositions />
      </div>
    );
  }
}

export default NodeDeveloper;
