import TwoPart from '../../components/TwoPart';
import React, { Component } from 'react';
import FluidImage from '../../components/FluidImage';
import TextPart from '../../components/TextPart';
import MultiPart from '../../components/MultiPart';
import QuotePart from '../../components/QuotePart';
import TwoPartImageText from '../../components/TwoPartImageText';
import TwoPartScroller from '../../components/TwoPartScroller/TwoPartScroller';
class UberPage extends Component {
  state = {};
  render() {
    return (
      <div className="case-studies">
        <TextPart>
          <h1>Uber Design</h1>
          <h2>Looking under the hood</h2>
        </TextPart>
        <FluidImage imageSrc="https://cdn.ueno.co/build/d9e3abdec3b616bd05b0f25c00bfbf38.jpg" imageAlt="something" />
        <TwoPart>
          <div>
            <h1>
              <div>Design, designer, designest</div>
            </h1>
            <h2>In collaboration with the design team at Uber, we created a site to showcase their work.</h2>
          </div>
          <div>
            To celebrate the work of its internal design teams, attract new talent and foster design thinking in the company, Uber decided to create
            uber.design. We worked with them on the design and the development of the site, bringing an outsider’s perspective and making sure the end product
            reflected the whole spectrum of design work being done at Uber. Work started in January 2017 and the site went live in March.
          </div>
        </TwoPart>
        <QuotePart>
          Uber wanted a site to share and show off the hard work of their internal design teams. We designed the home page of uber.design as a collage-style
          feed, a great way for visitors to catch a glimpse of what the teams have been up to.
        </QuotePart>
        <TwoPartImageText imageSrc="https://image.freepik.com/free-vector/passionate-red-rose_23-2147502749.jpg" imageAlt="something">
          Text
        </TwoPartImageText>
        <TwoPartScroller stickRight imageSrc="https://cdn.ueno.co/build/2290b325f17cdd375583ed796cef2fb5.jpg" imageAlt="something">
          <div>
            <h1>Welcome to the show</h1>
            <h2>Designing Uber</h2>
            <p>
              Uber wanted a site to share and show off the hard work of their internal design teams. We designed the home page of uber.design as a collage-style
              feed, a great way for visitors to catch a glimpse of what the teams have been up to.
            </p>
          </div>
        </TwoPartScroller>
        <MultiPart>
          <p>
            Uber wanted a site to share and show off the hard work of their internal design teams. We designed the home page of uber.design as a collage-style
            feed, a great way for visitors to catch a glimpse of what the teams have been up to.
          </p>
          <p>
            Uber wanted a site to share and show off the hard work of their internal design teams. We designed the home page of uber.design as a collage-style
            feed, a great way for visitors to catch a glimpse of what the teams have been up to.
          </p>
          <p>
            Uber wanted a site to share and show off the hard work of their internal design teams. We designed the home page of uber.design as a collage-style
            feed, a great way for visitors to catch a glimpse of what the teams have been up to.
          </p>
        </MultiPart>
        <TextPart>
          <p>
            Uber wanted a site to share and show off the hard work of their internal design teams. We designed the home page of uber.design as a collage-style
            feed, a great way for visitors to catch a glimpse of what the teams have been up to. Uber wanted a site to share and show off the hard work of their
            internal design teams. We designed the home page of uber.design as a collage-style feed, a great way for visitors to catch a glimpse of what the
            teams have been up to. Uber wanted a site to share and show off the hard work of their internal design teams. We designed the home page of
            uber.design as a collage-style feed, a great way for visitors to catch a glimpse of what the teams have been up to. Uber wanted a site to share and
            show off the hard work of their internal design teams. We designed the home page of uber.design as a collage-style feed, a great way for visitors to
            catch a glimpse of what the teams have been up to.
          </p>
        </TextPart>
      </div>
    );
  }
}

export default UberPage;
