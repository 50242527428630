var firebase = require('firebase');
var config = {
  apiKey: 'AIzaSyCdFglBzDqCl7_Z8arUOFWxcrhCMqaI3C8',
  authDomain: 'contenterra-b1246.firebaseapp.com',
  databaseURL: 'https://contenterra-b1246.firebaseio.com',
  projectId: 'contenterra-b1246',
  storageBucket: 'contenterra-b1246.appspot.com',
  messagingSenderId: '135147763295'
};
firebase.initializeApp(config);

export default firebase;
