import React, {Component} from 'react';
import TextAndBackground from '../../components/TextAndBackground';
import AllServices from './AllServices';
import WhyCooseUs from '../../components/WhyChoseUs';
import TextPart from '../../components/TextPart';
class OurServicesPage extends Component {
  render() {
    return (
      <div className="case-studies">
        <TextAndBackground
          blendWithNavBar={true}
          color="white"
          gradient={'linear-gradient(rgba(103, 102, 103, 0.44), rgba(83, 84, 86, 0.74))'}
          imageSrc="/images/services/services.jpg"
          imageAlt="ourservices"
          backgroudPosition="100% 100%">
          <br />
          <br />
          <h1>We offer tailored Software Consulting and Development services to meet your unique business requirements and goals.</h1>
          <br />
          <br />
        </TextAndBackground>
        <TextPart>
          <p>
            {/* We work with clients of various sizes from startups to large enterprises. We can take your requirements and build an entire application or work with
            your team to develop a piece of it. Our dedicated and experienced team will help you with your needs. */}
            ContenTerra has a strong track record of consistently architecting and building solutions for clients of various sizes from startups to large
            enterprises – on time and on budget. We can take your requirements and build an entire application or work with your team to develop a piece of it.
            Our dedicated and experienced team will help you with your needs. You gain rapid, adaptive, cost-effective solutions by taking advantage of our
            disciplined project execution approach, multi-disciplinary teams, and a seamless ability to blend the right mix of necessary resources to meet and
            adapt to your specific needs
            <br />
            Our responsive, vendor-agnostic approach ensures flexibility, leverages open source where feasible, and maximizes your return on investment. This
            technology-neutral viewpoint fosters high product quality and user adoption rates by exploiting the benefits of the latest technology in an agile,
            user-centric application development environment. The result: Your users gain access to more useful functionality at a lower cost.
          </p>
        </TextPart>
        <AllServices />
        <WhyCooseUs />
      </div>
    );
  }
}

export default OurServicesPage;
