import React, {PureComponent} from 'react';
import TextAndBackground from './TextAndBackground';
class WhyCooseUs extends PureComponent {
  state = {};
  render() {
    return (
      <TextAndBackground center={true} nopadding={true} bgColor="rgb(246, 250, 255)">
        <h3>Why choose us</h3>
        <div className="row choose-container boxx">
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="row service-link center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs">
              <div className="box">
                <svg className="icon-standard" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M22 18v-2H8V4h2L7 1 4 4h2v2H2v2h4v8c0 1.1.9 2 2 2h8v2h-2l3 3 3-3h-2v-2h4zM10 8h6v6h2V8c0-1.1-.9-2-2-2h-6v2z" fill="#1d365e" />
                </svg>
                <p>Transparent Approach</p>
                <small>You will be involved in every step and we communicate every small detail. No hidden agenda.</small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="row service-link center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs">
              <div className="box">
                <svg className="icon-standard" viewBox="0 0 24 24">
                  <path
                    fill="#1d365e"
                    d="M12,6A3,3 0 0,0 9,9A3,3 0 0,0 12,12A3,3 0 0,0 15,9A3,3 0 0,0 12,6M6,8.17A2.5,2.5 0 0,0 3.5,10.67A2.5,2.5 0 0,0 6,13.17C6.88,13.17 7.65,12.71 8.09,12.03C7.42,11.18 7,10.15 7,9C7,8.8 7,8.6 7.04,8.4C6.72,8.25 6.37,8.17 6,8.17M18,8.17C17.63,8.17 17.28,8.25 16.96,8.4C17,8.6 17,8.8 17,9C17,10.15 16.58,11.18 15.91,12.03C16.35,12.71 17.12,13.17 18,13.17A2.5,2.5 0 0,0 20.5,10.67A2.5,2.5 0 0,0 18,8.17M12,14C10,14 6,15 6,17V19H18V17C18,15 14,14 12,14M4.67,14.97C3,15.26 1,16.04 1,17.33V19H4V17C4,16.22 4.29,15.53 4.67,14.97M19.33,14.97C19.71,15.53 20,16.22 20,17V19H23V17.33C23,16.04 21,15.26 19.33,14.97Z"
                  />
                </svg>
                <p>Experienced &amp; Passionate Team</p>
                <small>Our team is passionate about what they do which directly translates to a higher quality.</small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="row service-link center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs">
              <div className="box">
                <svg className="icon-standard" viewBox="0 0 24 24">
                  <path
                    fill="#1d365e"
                    d="M7,2V4H8V18A4,4 0 0,0 12,22A4,4 0 0,0 16,18V4H17V2H7M11,16C10.4,16 10,15.6 10,15C10,14.4 10.4,14 11,14C11.6,14 12,14.4 12,15C12,15.6 11.6,16 11,16M13,12C12.4,12 12,11.6 12,11C12,10.4 12.4,10 13,10C13.6,10 14,10.4 14,11C14,11.6 13.6,12 13,12M14,7H10V4H14V7Z"
                  />
                </svg>
                <p>Quality Analyst Signoff</p>
                <small>Every deliverable will be verified by a QA on our end.</small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="row service-link center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs">
              <div className="box">
                <svg className="icon-standard" viewBox="0 0 24 24">
                  <path
                    fill="#1d365e"
                    d="M22.59,7.92L23.75,9.33L19,14.08L16.25,11.08L17.41,9.92L19,11.5L22.59,7.92M6,5A3,3 0 0,1 9,8A3,3 0 0,1 6,11A3,3 0 0,1 3,8A3,3 0 0,1 6,5M11,5A3,3 0 0,1 14,8A3,3 0 0,1 11,11C10.68,11 10.37,10.95 10.08,10.85C10.65,10.04 11,9.06 11,8C11,6.94 10.65,5.95 10.08,5.14C10.37,5.05 10.68,5 11,5M6,13C8,13 12,14 12,16V18H0V16C0,14 4,13 6,13M12.62,13.16C14.63,13.5 17,14.46 17,16V18H14V16C14,14.82 13.45,13.88 12.62,13.16Z"
                  />
                </svg>
                <p>PM oversight</p> <small>A project manager will provide oversight to ensure the project is on track.</small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="row service-link center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs">
              <div className="box">
                <svg className="icon-standard" viewBox="0 0 24 24">
                  <path
                    fill="#1d365e"
                    d="M3,13A9,9 0 0,0 12,22C12,17 7.97,13 3,13M12,5.5A2.5,2.5 0 0,1 14.5,8A2.5,2.5 0 0,1 12,10.5A2.5,2.5 0 0,1 9.5,8A2.5,2.5 0 0,1 12,5.5M5.6,10.25A2.5,2.5 0 0,0 8.1,12.75C8.63,12.75 9.12,12.58 9.5,12.31C9.5,12.37 9.5,12.43 9.5,12.5A2.5,2.5 0 0,0 12,15A2.5,2.5 0 0,0 14.5,12.5C14.5,12.43 14.5,12.37 14.5,12.31C14.88,12.58 15.37,12.75 15.9,12.75C17.28,12.75 18.4,11.63 18.4,10.25C18.4,9.25 17.81,8.4 16.97,8C17.81,7.6 18.4,6.74 18.4,5.75C18.4,4.37 17.28,3.25 15.9,3.25C15.37,3.25 14.88,3.41 14.5,3.69C14.5,3.63 14.5,3.56 14.5,3.5A2.5,2.5 0 0,0 12,1A2.5,2.5 0 0,0 9.5,3.5C9.5,3.56 9.5,3.63 9.5,3.69C9.12,3.41 8.63,3.25 8.1,3.25A2.5,2.5 0 0,0 5.6,5.75C5.6,6.74 6.19,7.6 7.03,8C6.19,8.4 5.6,9.25 5.6,10.25M12,22A9,9 0 0,0 21,13C16,13 12,17 12,22Z"
                  />
                </svg>
                <p>Flexible engagement models</p>
                <small>You can choose from a dedicated team or some resources on a time &amp; materials or fixed fee basis.</small>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div className="row service-link center-lg middle-lg center-md middle-md center-sm middle-sm center-xs middle-xs">
              <div className="box">
                <svg className="icon-standard" viewBox="0 0 24 24">
                  <path
                    fill="#1d365e"
                    d="M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12Z"
                  />
                </svg>
                <p>Satisfaction Guaranteed</p> <small>Don’t pay if you are not satisfied.</small>
              </div>
            </div>
          </div>
        </div>
      </TextAndBackground>
    );
  }
}

export default WhyCooseUs;
